import { Model } from "@nozbe/watermelondb";
import { field, children, readonly, date, immutableRelation } from "@nozbe/watermelondb/decorators";

export default class Device extends Model {
  static table = "devices";
  static associations = {
    scales: { type: "has_many", foreignKey: "device_id" },
    printers: { type: "has_many", foreignKey: "device_id" },
  };

  @field("name") name;
  @field("type") type;
  @field("bluetooth_id") bluetoothId;
  @field("auto_connect") autoConnect;
  @field("connection_type") connectionType;

  @children("scales") scales;
  @children("printers") printers;

  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
}