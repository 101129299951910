import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import React from "react";
import { useTranslation } from "react-i18next";
import { Loader2 } from "lucide-react";

export const Profile = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  return (
    <div>
      <h3 className="text-xl font-bold tracking-tight">
        {t("routes.account.profile.name")}
      </h3>
      <p className="text-muted-foreground">
        {t("routes.account.profile.description")}
      </p>
      <Separator className="my-6" />
      <div className="grid gap-4">
        <div className="grid gap-2">
          <Label htmlFor={"email"} className={"font-bold"}>
            {t("auth.email")}
          </Label>
          <Input id={"email"} readonly value="test@gmail.com"></Input>
        </div>

        <div className="grid gap-2">
          <Label htmlFor={"first-name"} className={"font-bold"}>
            {t("auth.user.first_name")}
          </Label>
          <Input id="first-name" value="test"></Input>
        </div>

        <div className="grid gap-2">
          <Label htmlFor={"last-name"} className={"font-bold"}>
            {t("auth.user.last_name")}
          </Label>
          <Input id="last-name" value="user"></Input>
        </div>

        <div className="grid gap-2">
          <Label htmlFor={"password"} className={"font-bold"}>
            {t("auth.password")}
          </Label>
          <Input id="password" type="password" value="password"></Input>

          <Label htmlFor={"confirm-password"} className={"font-bold"}>
            {t("auth.confirm_password")}
          </Label>
          <Input id="confirm-password" type="password" value="password"></Input>
        </div>

        <div className="grid gap-2">
          <Button className={"w-fit"} size="sm" disabled={loading}>
            {loading ? (
              <Loader2 className="h-5 w-5 animate-spin" />
            ) : (
              t("common.save_changes")
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
