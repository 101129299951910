import React from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Link } from "@radix-ui/themes";
import { Link as ReactRouterLink } from "react-router-dom";
import { AlertCircle } from "lucide-react";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import BackgroundImage from "../../assets/blob-scene-haikei.svg";
import { useTranslation } from "react-i18next";
import { createClient } from "@supabase/supabase-js";

import { ProjectKey, ProjectURL } from "../../configs/supabase";
import { Loader2 } from "lucide-react";

const supabase = createClient(ProjectURL, ProjectKey);

export function Signup() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleSignup = async (event) => {};

  const { t } = useTranslation();
  return (
    <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px] h-screen">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">{t("auth.signup.title")}</h1>
            <p className="text-balance text-muted-foreground">
              {t("auth.signup.prompt")}
            </p>
            {error && (
              <Alert variant="destructive" className="text-left">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>{t("common.error")}</AlertTitle>
                <AlertDescription>{t(error)}</AlertDescription>
              </Alert>
            )}
          </div>
          <div className="grid gap-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="first-name">{t("auth.user.first_name")}</Label>
                <Input
                  id="first-name"
                  value={firstName}
                  placeholder={t("auth.signup.first_name.placeholder")}
                  required
                  onChange={(ev) => {
                    setFirstName(ev.target.value);
                  }}
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="last-name">{t("auth.user.last_name")}</Label>
                <Input
                  id="last-name"
                  value={lastName}
                  placeholder={t("auth.signup.last_name.placeholder")}
                  required
                  onChange={(ev) => {
                    setLastName(ev.target.value);
                  }}
                />
              </div>
            </div>
            <div className="grid gap-2">
              <Label htmlFor="email">{t("auth.email")}</Label>
              <Input
                id="email"
                type="email"
                value={email}
                placeholder="m@example.com"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="password">{t("auth.password")}</Label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
              autocomplete={"new-password"} />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="password">{t('auth.confirm_password')}</Label>
                <Input id="confirm_password" type="password" value={confirmPassword} onChange={(ev) => setConfirmPassword(ev.target.value)} autocomplete={"new-password"} />
            </div>
            <Button
              type="submit"
              id="signup-button"
              className="w-full"
              disabled={loading}>
              {loading ? (
                <Loader2 className="h-5 w-5 animate-spin" />
              ) : (
                t("auth.signup.button")
              )}
            </Button>
          </div>
          <div className="mt-4 text-center text-sm">
            {t("auth.already_have_account")}{" "}
            <Link asChild className="underline">
              <ReactRouterLink to="/login">
                {t("auth.signin.link")}
              </ReactRouterLink>
            </Link>
          </div>
        </div>
      </div>
      <div className="hidden bg-muted lg:block">
        <img
          src={BackgroundImage}
          alt="Background"
          className="object-cover object-center h-full w-full"
        />
      </div>
    </div>
  );
}

export default Signup;
