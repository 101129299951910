import React from "react";
import "./App.css";
import { Theme } from "@radix-ui/themes";
import routes from "./routes";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { BLEProvider } from "./contexts/BLEContext/BLEContext";
import { SessionProvider } from "./contexts/SessionContext";
import { Toaster } from "./components/ui/toaster";
import { DeviceProvider } from "./contexts/DeviceContext";

const AppRouter = createBrowserRouter(routes);

function App() {
  return (
    <Theme
      accentColor="blue"
      radius="small"
      appearance="light"
      panelBackground="translucent"
    >
      <Toaster />
      <SessionProvider>
        <BLEProvider>
          <DeviceProvider>
            <RouterProvider router={AppRouter} />
          </DeviceProvider>
        </BLEProvider>
      </SessionProvider>
    </Theme>
  );
}

export default App;
