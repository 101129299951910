export class WeighingScale {

    constructor() {
        this._stable = false;
        this._totalWeight = 0;
        this._tareWeight = 0;
        this._totalWeightUnit = 'g';
        this._tareWeight = 0;
        this._tareWeightUnit = 'g';
        this._netWeight = 0;
        this._netWeightUnit = 'g';
    }

    async connect() {
        throw new Error('Connect method not implemented');
    }

    async disconnect() {
        throw new Error('Disconnect method not implemented');
    }

    get totalWeight() {
        return this._totalWeight;
    }

    get totalWeightUnit() {
        return this._unit;
    }

    get tareWeight() {
        return this._tareWeight;
    }

    get tareWeightUnit() {
        return this._tareWeightUnit;
    }

    get netWeight() {
        return this._netWeight;
    }

    get netWeightUnit() {
        return this._netWeightUnit;
    }

    get stable() {
        return this._stable;
    }
}

export default WeighingScale;