import React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SidebarItem = ({ isCollapsed, item }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const Icon = item.icon;
  return (
    isCollapsed? (<Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className={`rounded-lg ${item.active ? "bg-muted" : ""}`}
          aria-label={t(item.name)}
          onClick={() => {
            navigate(item.path);
          }}>
          <Icon className="size-5" />
        </Button>
      </TooltipTrigger>
      <TooltipContent side="right" sideOffset={5}>
        {t(item.description)}
      </TooltipContent>
    </Tooltip>) : (
      <Button
        variant="ghost"
        className={`rounded-lg ${item.active ? "bg-muted" : ""} justify-start`}
        aria-label={t(item.name)}
        onClick={() => {
          navigate(item.path);
        }}>
        {item.icon ? <Icon className="size-5 mr-5" /> : null}
        {t(item.name)}
      </Button>
    )
  );
};

export const Sidebar = ({ isCollapsed = true, items }) => {

  return (
    <aside className={`inset-y flex h-full flex-col border-r bg-background ${isCollapsed ? "" : "w-[200px]"}`}>
      <nav data-collapsed={isCollapsed} className="grid gap-1 p-2">
        {items
          .filter((i) => i.position === "top")
          .map((item, index) => (
            <SidebarItem
              key={index}
              item={item}
              isCollapsed={isCollapsed}
            />
          ))}
      </nav>
      <nav className="mt-auto grid gap-1 p-2">
        {items
          .filter((i) => i.position === "bottom")
          .map((item, index) => (
            <SidebarItem
              key={index}
              item={item}
              isCollapsed={isCollapsed}
            />
          ))}
      </nav>
    </aside>
  );
};

export default Sidebar;
