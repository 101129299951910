import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import dayjs from "dayjs";
import {
    CalendarIcon,
    X
} from "lucide-react";


export const DateTimeRangePicker = ({
  dateTimeRange,
  setDateTimeRange,
  clearDateTimes,
}) => {
  const handleSelect = (range) => {
    if (range?.from && range?.to) {
      setDateTimeRange({
        from: range.from,
        to: range.to,
      });
    } else {
      setDateTimeRange(range);
    }
  };

  const formatDateTime = (date) => {
    return dayjs(date).format("MMM D, YYYY HH:mm");
  };

  return (
    <div className="flex items-center space-x-2">
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            className={cn(
              "w-[300px] justify-start text-left font-normal",
              !dateTimeRange && "text-muted-foreground",
            )}>
            <CalendarIcon className="mr-2 h-4 w-4" />
            {dateTimeRange?.from ? (
              dateTimeRange.to ? (
                <>
                  {formatDateTime(dateTimeRange.from)} -{" "}
                  {formatDateTime(dateTimeRange.to)}
                </>
              ) : (
                formatDateTime(dateTimeRange.from)
              )
            ) : (
              <span>Pick a date and time range</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <div className="p-4">
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={dateTimeRange?.from}
              selected={dateTimeRange}
              onSelect={handleSelect}
              numberOfMonths={2}
              max={90} // Maximum 90 days selection
            />
            <div className="mt-4 flex justify-between">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Start Time
                </label>
                <input
                  type="time"
                  value={
                    dateTimeRange?.from
                      ? dayjs(dateTimeRange.from).format("HH:mm")
                      : ""
                  }
                  onChange={(e) => {
                    const [hours, minutes] = e.target.value.split(":");
                    const newFrom = dayjs(dateTimeRange?.from || new Date())
                      .hour(parseInt(hours))
                      .minute(parseInt(minutes))
                      .toDate();
                    handleSelect({ ...dateTimeRange, from: newFrom });
                  }}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  End Time
                </label>
                <input
                  type="time"
                  value={
                    dateTimeRange?.to
                      ? dayjs(dateTimeRange.to).format("HH:mm")
                      : ""
                  }
                  onChange={(e) => {
                    const [hours, minutes] = e.target.value.split(":");
                    const newTo = dayjs(dateTimeRange?.to || new Date())
                      .hour(parseInt(hours))
                      .minute(parseInt(minutes))
                      .toDate();
                    handleSelect({ ...dateTimeRange, to: newTo });
                  }}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                />
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
      {dateTimeRange && (
        <Button variant="ghost" size="icon" onClick={clearDateTimes}>
          <X className="h-4 w-4" />
        </Button>
      )}
    </div>
  );
};

export default DateTimeRangePicker;