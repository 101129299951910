// app/model/migrations.js

import {
  schemaMigrations,
  createTable,
} from "@nozbe/watermelondb/Schema/migrations";

export default schemaMigrations({
  migrations: [
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 5,
      steps: [
        // See "Migrations API" for more details
        createTable({
          name: "inventory_items",
          columns: [
            { name: "key_value", type: "string", isIndexed: true },
            { name: "created_at", type: "number" },
            { name: "updated_at", type: "number" },
          ],
        }),
        createTable({
          name: "inventory_item_fields",
          columns: [
            { name: "item_id", type: "string", isIndexed: true },
            { name: "field_name", type: "string" },
            { name: "field_value", type: "string" },
            { name: "created_at", type: "number" },
            { name: "updated_at", type: "number" },
          ],
        }),
      ],
    },
  ],
});
