import React from "react";

export const BLEContext = React.createContext(null);

export const BLEProvider = ({ children }) => {
  const isBLEAvailable = Boolean(navigator.bluetooth);
  const connectedDevices = React.useRef(new Map());

  /**
   * Requests a Bluetooth device with the specified parameters.
   *
   * @param {Object} params - The parameters for requesting the device.
   * @returns {Promise<BluetoothDevice>} A promise that resolves to the requested Bluetooth device.
   */
  const requestDevice = React.useCallback(async (params) => {
    try {
      const device = await navigator.bluetooth.requestDevice(params);

      return device;
    } catch (error) {
      console.error(error);
    }
  }, []);

  React.useEffect(() => {
    return () => {
      for (const device of connectedDevices.current.values()) {
        device.removeEventListener('advertisementreceived')
      }
    };
  }, []);

  return (
    <BLEContext.Provider
      value={{
        isBLEAvailable,
        requestDevice,
        connectedDevices,
      }}
    >
      {children}
    </BLEContext.Provider>
  );
};

export const useBLE = () => {
  const context = React.useContext(BLEContext);

  if (context === undefined) {
    throw new Error("useBLE must be used within a BLEProvider");
  }

  return context;
};
