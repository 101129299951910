import React from "react";
import { PageLayout } from "../PageLayout/PageLayout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { CircleUser } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "@radix-ui/themes";
import {
  Outlet,
  useMatches,
  useLocation,
  Navigate,
  Link as ReactRouterLink,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createClient } from "@supabase/supabase-js";
import { ProjectURL, ProjectKey } from "@/configs/supabase";
import { DatabaseProvider } from "@/contexts/DatabaseContext/DatabaseContext";
import { useSession } from "@/contexts/SessionContext";

const supabase = createClient(ProjectURL, ProjectKey);

export const ProtectedRoute = (props) => {
  const matches = useMatches();
  const location = useLocation();
  const { session, user } = useSession();
  const { t } = useTranslation();
  const sidebarItems = React.useMemo(() => {
    const mainRoutes = matches.find(
      (match) => match.handle.name === "route.layout.name"
    ).handle.mainRoutes;

    if (!mainRoutes) {
      return [];
    }

    return mainRoutes.map((route) => {
      return {
        path: route.path,
        icon: route.handle.icon,
        name: route.handle.name,
        description: route.handle.description,
        active: location.pathname === "/" + route.path,
        position: route.handle.position,
      };
    });
  }, [matches, location]);

  const handleSignOutClick = async () => {
    await supabase.auth.signOut();
  };

  if (!session) {
    return (
      <Navigate
        to={"/login"}
        state={{
          referer: location.pathname,
        }}
      />
    );
  }

  const dbName = user?.id ? `scale-db-${user.id}` : null;

  return (
    <DatabaseProvider dbName={dbName}>
      {" "}
      {/* TODO: dynamic database name based on user id */}
      <PageLayout sidebarItems={sidebarItems}>
        <div className="flex flex-col gap-4 py-4 px-4 w-full">
          <div className="flex flex-row justify-end md:justify-between items-center w-full">
            <Breadcrumb className="hidden md:flex">
              <BreadcrumbList>
                {matches.slice(1).map((match, index) => {
                  if (!match.handle) return null;

                  return (
                    <React.Fragment key={index}>
                      <BreadcrumbItem>
                        {index !== matches.slice(1).length - 1 ? (
                          <BreadcrumbLink asChild>
                            <Link asChild>
                              <ReactRouterLink to={match.pathname}>
                                {t(match.handle.name)}
                              </ReactRouterLink>
                            </Link>
                          </BreadcrumbLink>
                        ) : (
                          <BreadcrumbPage>
                            {t(match.handle.name)}
                          </BreadcrumbPage>
                        )}
                      </BreadcrumbItem>
                      {index !== matches.slice(1).length - 1 && (
                        <BreadcrumbSeparator />
                      )}
                    </React.Fragment>
                  );
                })}
              </BreadcrumbList>
            </Breadcrumb>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="secondary"
                  size="icon"
                  className="rounded-full"
                >
                  <CircleUser className="h-5 w-5" />
                  <span className="sr-only">Toggle user menu</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>{t("user.my_account")}</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem asChild>
                  <ReactRouterLink to={"/account/profile"}>
                    {t("common.setting")}
                  </ReactRouterLink>
                </DropdownMenuItem>
                <DropdownMenuItem>{t("common.support")}</DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={handleSignOutClick}>
                  {t("auth.sign_out")}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          <div className="pb-4">
            <Outlet />
          </div>
        </div>
      </PageLayout>
    </DatabaseProvider>
  );
};

export default ProtectedRoute;
