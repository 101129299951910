import { Model } from "@nozbe/watermelondb";
import {
  field,
  relation,
  readonly,
  date,
} from "@nozbe/watermelondb/decorators";

export default class PrinterModel extends Model {
  static table = "printer_models";

  @field("name") name;
  @field("max_paper_width") maxPaperWidth;
  @field("max_paper_height") maxPaperHeight;
  @field("dpi") dpi;
  @field("supports_continuous_paper") supportsContinuousPaper;

  @relation("printer_vendors", "vendor_id") vendor;

  @readonly @date("created_at") createdAt;
  @readonly @date("updated_at") updatedAt;
}
