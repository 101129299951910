import { Model } from "@nozbe/watermelondb";
import {
  field,
  children,
  relation,
  readonly,
  date,
} from "@nozbe/watermelondb/decorators";

export default class Measurement extends Model {
  static table = "measurements";
  static associations = {
    scales: { type: "belongs_to", key: "scale_id" },
    measurement_fields: { type: "has_many", foreignKey: "measurement_id" },
  };

  @field("timestamp") timestamp;
  @field("tare_weight") tareWeight;
  @field("tare_unit") tareUnit;
  @field("total_weight") totalWeight;
  @field("total_unit") totalUnit;
  @field("single_weight") singleWeight;
  @field("single_unit") singleUnit;
  @field("net_weight") netWeight;
  @field("net_unit") netUnit;
  @field("price") price;
  @field("price_unit") priceUnit;
  @field("name") name;
  @field("product_no") productNo;

  @children("measurement_fields") measurementFields;

  @relation("scales", "scale_id") scale;

  @readonly @date("created_at") createdAt;
  @readonly @date("updated_at") updatedAt;
}
