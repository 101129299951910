import React, { createContext } from "react";
import { Database } from "@nozbe/watermelondb";
import LokiJSAdapter from "@nozbe/watermelondb/adapters/lokijs";
import appSchema from "./models/schema";
import migrations from "./models/migrations";

import Device from "./models/Device";
import Printer from "./models/Printer";
import PrinterVendor from "./models/PrinterVendor";
import PrinterModel from "./models/PrinterModel";
import Scale from "./models/Scale";
import Measurement from "./models/Measurement";
import MeasurementField from "./models/MeasurementField";
import LabelTemplate from "./models/LabelTemplate";

import scaleVendorModelSeeder from "./seeders/scaleVendorModelSeeder";
import ScaleVendor from "./models/ScaleVendor";
import ScaleModel from "./models/ScaleModel";
import InventoryItem from "./models/InventoryItem";
import InventoryItemField from "./models/InventoryItemField";

const DatabaseContext = createContext();
const DefaultDatabaseName = "scale-db";

const DatabaseProvider = ({ dbName, children }) => {
  const _dbName = dbName || DefaultDatabaseName;
  const adapter = React.useMemo(
    () =>
      new LokiJSAdapter({
        dbName: _dbName,
        schema: appSchema,
        useWebWorker: false,
        useIncrementalIndexedDB: true,
      }),
    [_dbName]
  );

  const database = React.useMemo(
    () =>
      new Database({
        adapter,
        migrations,
        modelClasses: [
          // ... your model classes go here
          Device,
          Printer,
          PrinterVendor,
          PrinterModel,
          Scale,
          Measurement,
          MeasurementField,
          LabelTemplate,
          ScaleVendor,
          ScaleModel,
          PrinterVendor,
          PrinterModel,
          InventoryItem,
          InventoryItemField,
        ],
      }),
    [adapter]
  );

  React.useEffect(() => {
    // seed databases
    scaleVendorModelSeeder(database);

    window.database = database;
  }, [database]);

  return (
    <DatabaseContext.Provider value={{ database }}>
      {children}
    </DatabaseContext.Provider>
  );
};

export const useDatabase = () => {
  const context = React.useContext(DatabaseContext);

  if (context === undefined) {
    throw new Error("useDatabase must be used within a DatabaseProvider");
  }

  return context;
};

export { DatabaseContext, DatabaseProvider };
