import React from "react";
import { Flex } from "@radix-ui/themes";
import { Sidebar } from "../Sidebar/Sidebar";

export const PageLayout = ({ sidebarItems, children }) => {
  return (
    <Flex className="h-screen bg-muted/40" direction="row">
      <Sidebar items={sidebarItems} />

      <Flex className="flex-1 overflow-y-auto">{children}</Flex>
    </Flex>
  );
};

export default PageLayout;
