import { Model } from "@nozbe/watermelondb";
import {
  field,
  relation,
  readonly,
  date,
} from "@nozbe/watermelondb/decorators";

export default class ScaleModel extends Model {
  static table = "scale_models";
  static associations = {
    scale_vendors: { type: "belongs_to", key: "vendor_id" },
  };

  @field("name") name;
  @field("max_weight") maxWeight;
  @field("max_unit") maxUnit;
  @field("min_weight") minWeight;
  @field("min_unit") minUnit;
  @field("resolution") resolution;
  @field("supports_price_calculation") supportsPriceCalculation;
  @field("supports_counting") supportsCounting;
  @field("supports_ble") supportsBle;
  @field("supports_tcpip") supportsTcpip;
  @field("supports_serial") supportsSerial;
  @field("ble_service_uuid") bleServiceUuid;
  @field("ble_char_uuid") bleCharUuid;

  @relation("scale_vendors", "vendor_id") vendor;

  @readonly @date("created_at") createdAt;
  @readonly @date("updated_at") updatedAt;
}
