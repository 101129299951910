// models/LabelTemplate.js
import { Model } from "@nozbe/watermelondb";
import { field, readonly, date } from "@nozbe/watermelondb/decorators";

export default class LabelTemplate extends Model {
  static table = "label_templates";

  @field("name") name;
  @field("tspl_content") tsplContent;
  @field("width") width;
  @field("height") height;
  @field("is_default") isDefault;
  
  @readonly @date('created_at') createdAt
  @readonly @date('updated_at') updatedAt
}