import CountingScale from '../CountingScale';

const BUFFER_SIZE = 32;

export class DNC extends CountingScale {

    static SERVICE_UUID = '0xfff0';
    static CHARACTERISTIC_UUID = '0xfff1';

    constructor(adapter) {
        super();
        this._adapter = adapter;
        this._buf = []
    }

    onData(event) {
        const data = new TextDecoder().decode(event.target.value.buffer);
        this._buf.push(data);

        if (this._buf.length > BUFFER_SIZE) {
            this._buf.shift();
        }
    }

    get adapter() {
        return this._adapter;
    }
    
    async connect() {
        await this._adapter.connect(parseInt(DNC.SERVICE_UUID, 16), parseInt(DNC.CHARACTERISTIC_UUID, 16));
        await this._adapter.notify(this.onData.bind(this))

        this._interval = setInterval(() => {

            if (this._buf.length === 0) {
                return;
            }

            const lines = this._buf.join('').split('\n');
            if (lines.length >= 6) {
    
                let startLine = lines.length - 1;
                for (; startLine > 0; startLine--) {
                    if (lines[startLine].match(/DNC/)) {
                        break;
                    }
                }
                let [, stability, totalWeight, totalWeightUnit] = lines[startLine - 5].match(
                    /([SU])([- ][\d\.]{6,7})(g|kg)/
                );
                let [, singleWeight, singleWeightUnit] = lines[startLine - 4].match(/([\d\.]{6,7})(g|kg)/);
                let [, quantity] = lines[startLine - 3].match(/(\d{6})pcs/);
                let [, netWeight, netWeightUnit] = lines[startLine - 2].match(/([\d\.]{6,7})(g|kg)/);
                let [, tareWeight, tareWeightUnit] = lines[startLine - 1].match(/([\d\.]{6,7})(g|kg)/);
    
                this._stable = stability === 'S';
                this._totalWeight = parseFloat(totalWeight);
                this._totalWeightUnit = totalWeightUnit;
                this._singleWeight = parseFloat(singleWeight);
                this._singleWeightUnit = singleWeightUnit;
                this._tareWeight = parseFloat(tareWeight);
                this._tareWeightUnit = tareWeightUnit;
                this._netWeight = parseFloat(netWeight);
                this._netWeightUnit = netWeightUnit;
                this._quantity = parseInt(quantity);

                //console.log('DNC data', this._buf)
                //console.log(this._stable, this._totalWeight, this._totalWeightUnit, this._singleWeight, this._singleWeightUnit, this._tareWeight, this._tareWeightUnit, this._netWeight, this._netWeightUnit, this._quantity)
            }

            if (this._onMeasurement) {
                this._onMeasurement({
                    stable: this._stable,
                    totalWeight: this._totalWeight,
                    totalWeightUnit: this._totalWeightUnit,
                    singleWeight: this._singleWeight,
                    singleWeightUnit: this._singleWeightUnit,
                    tareWeight: this._tareWeight,
                    tareWeightUnit: this._tareWeightUnit,
                    netWeight: this._netWeight,
                    netWeightUnit: this._netWeightUnit,
                    quantity: this._quantity
                });
            }
        }, 500);
    }
    
    async disconnect() {
        await this._adapter.disconnect();
        if (this._interval) {
            clearInterval(this._interval);
        }
    }
}

export default DNC;