import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import axios from "axios";

const SIMPLE_LOCALIZE_PROJECT_TOKEN = "f2ef3fff6925417399a7ed9c25e0bfd0";
const SIMPLE_LOCALIZE_API_KEY =
  "5dF7df72deB9fE46B5868270518982bAC974eF6Be8E443BF";
const SIMPLE_LOCALIZE_API_BASE = "https://api.simplelocalize.io/api";
const SIMPLE_LOCALIZE_CDN_BASE = "https://cdn.simplelocalize.io";
const SIMPLE_LOCALIZE_ENVIRONMENT = "_latest";

const FALLBACK_LANGUAGE = "zh-TW";

const LOAD_PATH = `${SIMPLE_LOCALIZE_CDN_BASE}/${SIMPLE_LOCALIZE_PROJECT_TOKEN}/${SIMPLE_LOCALIZE_ENVIRONMENT}/{{lng}}/{{ns}}`;

const configuration = {
  headers: {
    "X-SimpleLocalize-Token": SIMPLE_LOCALIZE_API_KEY,
  },
};
const missingKeys = [];

const createTranslationKeys = async (requestBody) =>
  axios.post(
    `${SIMPLE_LOCALIZE_API_BASE}/v1/translation-keys/bulk`,
    requestBody,
    configuration
  );
const updateTranslations = async (requestBody) =>
  axios.patch(
    `${SIMPLE_LOCALIZE_API_BASE}/v2/translations/bulk`,
    requestBody,
    configuration
  );
const saveMissing = async () => {
  if (missingKeys.length === 0) {
    return;
  }
  console.info(`Saving ${missingKeys.length} missing translation keys`);

  const translationKeys = missingKeys.map((element) => ({
    key: element.translationKey,
    namespace: element.namespace,
  }));

  await createTranslationKeys({ translationKeys }).catch((error) =>
    console.error(`Error during creating translation keys: ${error}`)
  );

  const translations = missingKeys.map((element) => ({
    key: element.translationKey,
    namespace: element.namespace,
    language: element.language,
    text: element.fallbackValue,
  }));
  await updateTranslations({ translations }).catch((error) =>
    console.error(`Error during updating translations: ${error}`)
  );
  missingKeys.length = 0;
};

setInterval(async () => {
  await saveMissing();
}, 30_000);

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    ns: ["default"],
    fallbackLng: FALLBACK_LANGUAGE,
    backend: {
      loadPath: LOAD_PATH,
    },
    saveMissing: true,
    missingKeyHandler: async (
      languages,
      namespace,
      translationKey,
      fallbackValue
    ) => {
      console.debug(
        `[${namespace}][${translationKey}] not available in Translation Hosting`
      );
      missingKeys.push({
        translationKey: translationKey,
        namespace: namespace ?? "",
        language: languages[0] ?? FALLBACK_LANGUAGE,
        fallbackValue: fallbackValue ?? "",
      });
    },
  });

export default i18next;
