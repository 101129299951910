import { Model } from "@nozbe/watermelondb";
import {
  field,
  relation,
  readonly,
  date,
} from "@nozbe/watermelondb/decorators";

export default class Scale extends Model {
  static table = "scales";
  static associations = {
    devices: { type: "belongs_to", key: "device_id" },
    scale_models: { type: "belongs_to", key: "model_id" },
    measurements: { type: "has_many", foreignKey: "scale_id" },
  };

  @field("device_id") deviceId;
  @field("scale_type") scaleType;

  @relation("scale_models", "model_id") model;
  @relation("devices", "device_id") device;
  @relation("measurements", "scale_id") measurements;

  @readonly @date("created_at") createdAt;
  @readonly @date("updated_at") updatedAt;
}
