import { Q } from "@nozbe/watermelondb";

export const scaleVendorModelSeeder = async (database) => {

    const vendors = [
        {
            name: "東成衡器廠"
        },
    ];

    const models = [
        {
            vendorName: "東成衡器廠",
            name: "DNC-15",
            maxWeight: 15,
            maxUnit: "kg",
            minWeight: 0.5,
            minUnit: "g",
            resolution: 0.5,
            supportsPriceCalculation: false,
            supportsCounting: true,
            supportsBle: true,
            supportsTcpip: false,
            supportsSerial: false,
            bleServiceUuid: "0xffe0",
            bleCharUuid: "0xfff1",
        },
    ];

    const scaleVendorsCollection = database.get("scale_vendors");
    const scaleModelsCollection = database.get("scale_models");

    if (!scaleVendorsCollection || !scaleModelsCollection) {
        throw new Error("Collection not found");
    }

    await database.write(async () => {
        // check whether each vendor is existed or not by checking the name, if not, create it
        await Promise.all(vendors.map(async (vendor) => {
            const existingVendor = await scaleVendorsCollection.query(Q.where("name", vendor.name)).fetch();

            if (existingVendor.length === 0) {
                await scaleVendorsCollection.create((newVendor) => {
                    newVendor.name = vendor.name;
                });
            }
        }));
    });

    await database.write(async () => {
        // check whether each model is existed or not by checking the name, if not, create it
        await Promise.all(models.map(async (model) => {
            const vendor = await scaleVendorsCollection.query(Q.where("name", model.vendorName)).fetch();
            const existingModel = await scaleModelsCollection.query(Q.where("name", model.name)).fetch();

            if (existingModel.length === 0) {
                await scaleModelsCollection.create((newModel) => {
                    newModel.vendor.set(vendor[0]);
                    newModel.name = model.name;
                    newModel.maxWeight = model.maxWeight;
                    newModel.maxUnit = model.maxUnit;
                    newModel.minWeight = model.minWeight;
                    newModel.minUnit = model.minUnit;
                    newModel.resolution = model.resolution;
                    newModel.supportsPriceCalculation = model.supportsPriceCalculation || false;
                    newModel.supportsCounting = model.supportsCounting || false;
                    newModel.supportsBle = model.supportsBle || false;
                    newModel.supportsTcPip = model.supportsTcpip || false;
                    newModel.supportsSerial = model.supportsSerial || false;
                    newModel.bleServiceUuid = model.bleServiceUuid;
                    newModel.bleCharUuid = model.bleCharUuid;
                });
            }
        }));
    });
}

export default scaleVendorModelSeeder;