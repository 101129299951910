import { appSchema, tableSchema } from "@nozbe/watermelondb";

export default appSchema({
  version: 5,
  tables: [
    tableSchema({
      name: "devices",
      columns: [
        { name: "name", type: "string" },
        { name: "type", type: "string" }, // 'scale' or 'printer'
        { name: "bluetooth_id", type: "string" },
        { name: "auto_connect", type: "boolean" },
        { name: "connection_type", type: "string" },
        // ... other device-specific fields
      ],
    }),
    tableSchema({
      name: "scale_vendors",
      columns: [{ name: "name", type: "string" }],
    }),
    tableSchema({
      name: "scale_models",
      columns: [
        { name: "vendor_id", type: "string", isIndexed: true },
        { name: "name", type: "string", isIndexed: true },
        { name: "max_weight", type: "number" },
        { name: "max_unit", type: "string" },
        { name: "min_weight", type: "number" },
        { name: "min_unit", type: "string" },
        { name: "resolution", type: "number" },
        { name: "supports_price_calculation", type: "boolean" },
        { name: "supports_counting", type: "boolean" },
        { name: "supports_ble", type: "boolean" },
        { name: "supports_tcpip", type: "boolean" },
        { name: "supports_serial", type: "boolean" },
        { name: "ble_service_uuid", type: "string" },
        { name: "ble_char_uuid", type: "string" },
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
    
      ],
    }),
    tableSchema({
      name: "scales",
      columns: [
        { name: "device_id", type: "string", isIndexed: true },
        { name: "model_id", type: "string", isIndexed: true },
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
    
        // ... other scale-specific fields
      ],
    }),
    tableSchema({
      name: "printer_vendors",
      columns: [{ name: "name", type: "string" },    { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
    ],
      
    }),
    tableSchema({
      name: "printer_models",
      columns: [
        { name: "vendor_id", type: "string", isIndexed: true },
        { name: "name", type: "string" },
        { name: "max_paper_width", type: "number" },
        { name: "max_paper_height", type: "number" },
        { name: "dpi", type: "number" },
        { name: "supports_continuous_paper", type: "boolean" },
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
    
      ],
    }),
    tableSchema({
      name: "printers",
      columns: [
        { name: "device_id", type: "string", isIndexed: true },
        { name: "printer_type", type: "string" },
        { name: "paper_width", type: "number" },
        { name: "paper_height", type: "number" },
        { name: "model_id", type: "string", isIndexed: true },
        { name: "is_continuous_paper", type: "boolean" },
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
    
        // ... other printer-specific fields
      ],
    }),
    tableSchema({
      name: "measurements",
      columns: [
        { name: "scale_id", type: "string", isIndexed: true },
        { name: "timestamp", type: "number" },
        { name: "tare_weight", type: "number" },
        { name: "tare_unit", type: "string" },
        { name: "total_weight", type: "number" },
        { name: "total_unit", type: "string" },
        { name: "single_weight", type: "number" },
        { name: "single_unit", type: "string" },
        { name: "net_weight", type: "number" },
        { name: "net_unit", type: "string" },
        { name: "price", type: "number" }, // For price-calculating scales
        { name: "price_unit", type: "string" }, // For price-calculating scales
        { name: "name", type: "string" }, // For counting scales
        { name: "product_no", type: "string" },

        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
    
      ],
    }),
    tableSchema({
      name: "measurement_fields",
      columns: [
        { name: "measurement_id", type: "string", isIndexed: true },
        { name: "field_name", type: "string" },
        { name: "field_value", type: "string" },

        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
    
      ],
    }),
    tableSchema({
      name: "label_templates",
      columns: [
        { name: "name", type: "string" },
        { name: "tspl_content", type: "string" },
        { name: "width", type: "number" },
        { name: "height", type: "number" },
        { name: "is_default", type: "boolean" },

        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },    
      ],
    }),
    tableSchema({
      name: "inventory_items",
      columns: [
        { name: "key_value", type: "string", isIndexed: true },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "inventory_item_fields",
      columns: [
        { name: "item_id", type: "string", isIndexed: true },
        { name: "field_name", type: "string" },
        { name: "field_value", type: "string" },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),
  ],
});
