export class CommunicationAdapter {
  async connect() {
    throw new Error("Connect method must be implemented");
  }

  async disconnect() {
    throw new Error("Disconnect method must be implemented");
  }

  async sendData(data) {
    throw new Error("sendData method must be implemented");
  }

  async receiveData() {
    throw new Error("receiveData method must be implemented");
  }
}

export default CommunicationAdapter;
