import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useTranslation } from "react-i18next";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useBLE } from "@/contexts/BLEContext/BLEContext";
import { useDatabase } from "@/contexts/DatabaseContext/DatabaseContext";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
} from "@/components/ui/select";
import React from "react";
import BluetoothAdapter from "@/drivers/adapters/Bluetooth";
import { useToast } from "@/components/ui/use-toast";
import { ToastAction } from "@radix-ui/react-toast";
import { Q } from "@nozbe/watermelondb";

export const AddScaleDialog = ({ open, setOpen, onClose }) => {
  const { requestDevice, isBLEAvailable } = useBLE();
  const { database } = useDatabase();
  const { t } = useTranslation();
  const [scaleVendors, setScaleVendors] = React.useState([]);
  const [scaleModels, setScaleModels] = React.useState([]);
  const [vendor, setVendor] = React.useState(null);
  const [model, setModel] = React.useState(null);
  const [name, setName] = React.useState("");
  const [connectionType, setConnectionType] = React.useState(null);
  const [bleDevice, setBLEDevice] = React.useState(null);
  const { toast } = useToast();

  const connectionTypes = [
    {
      key: t("devices.scale.connection_type.bluetooth"),
      name: t("devices.scale.connection_type.bluetooth"),
      value: "bluetooth",
      disabled: !isBLEAvailable,
    },
    {
      key: t("devices.scale.connection_type.serial"),
      name: t("devices.scale.connection_type.serial"),
      value: "serial",
      disabled: true,
    },
    {
      key: t("devices.scale.connection_type.tcpip"),
      name: t("devices.scale.connection_type.tcpip"),
      value: "tcpip",
      disabled: true,
    },
  ];

  /**
   * Handles the scanning of devices and sets the BLE device if found.
   * @returns {Promise<void>} A promise that resolves when the scanning is complete.
   */
  const handleScan = async () => {
    if (!model || !isBLEAvailable || connectionType !== "bluetooth") return;

    let requestParams = {};
    let serviceUUID = model.bleServiceUuid;

    if (!serviceUUID) {
      requestParams = {
        acceptAllDevices: true,
      };
    } else {
      /* check whether service uuid lenght is shorter than proper uuid length
           if so, then it is a short uuid and should be converted to decimal number */
      if (serviceUUID.startsWith("0x")) {
        serviceUUID = parseInt(serviceUUID, 16);
      }

      requestParams = {
        filters: [
          {
            services: [serviceUUID],
          },
        ],
        optionalServices: [parseInt("0xfff0", 16)],
      };
    }

    try {
      const device = await requestDevice(requestParams);

      if (device) {
        setBLEDevice(device);
        setName(device.name)
      }
    } catch (error) {
      console.error("Error requesting device", error);
    }
  };

  const handleAdd = async () => {
    if (!database || !model || !bleDevice || !connectionType) {
      toast({
        title: t("devices.scale.add_error"),
        description: t("devices.scale.add_error_description"),
        variant: "destructive",
      });
    }

    const devices = await database
      .get("devices")
      .query(Q.where("bluetooth_id", bleDevice.id))
      .fetch();
    if (devices.length > 0) {
      toast({
        title: t("devices.scale.add_error"),
        description: t("devices.scale.add_duplicate_error_description"),
        variant: "destructive",
      });
      setOpen(false);
      return;
    }

    await database.write(async () => {
      const device = await database.get("devices").create((newDevice) => {
        newDevice.name = name;
        newDevice.type = "scale";
        newDevice.bluetoothId = bleDevice.id;
        newDevice.connectionType = connectionType;
      });

      await database.get("scales").create((newScale) => {
        console.log(device, model);
        newScale.device.set(device);
        newScale.model.set(model);
      });
    });

    toast({
      title: t("devices.scale.add_success"),
      description: t("devices.scale.add_success_description"),
      action: (
        <ToastAction altText={t("common.connect")}>
          {t("common.connect")}
        </ToastAction>
      ),
    });

    setOpen(false);
  };

  React.useEffect(() => {
    const fetchVendors = async () => {
      if (!database) return;
      const vendors = await database.get("scale_vendors").query().fetch();
      setScaleVendors(vendors);
    };

    fetchVendors();
  }, [database]);

  React.useEffect(() => {
    const fetchModels = async () => {
      if (!vendor) return;

      // TODO: models should be filtered based on supported connection type
      const models = await database.get("scale_models").query().fetch();
      setScaleModels(models);
    };

    fetchModels();
  }, [vendor, database]);

  React.useEffect(() => {
    if (!open) {
      setVendor(null);
      setScaleModels([]);
      setModel(null);
      setName("");
      setConnectionType(null);
      setBLEDevice(null);
    }
  }, [open]);

  const shouldDisableSaveButton = React.useCallback(() => {
    if (!connectionType) return true;

    // TODO: add validation for serial and tcpip connection types
    switch (connectionType) {
      case "bluetooth":
        return !name || !vendor || !model || !connectionType || !bleDevice;
      default:
        return true;
    }
  }, [bleDevice, connectionType, model, name, vendor]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("devices.scale.add")}</DialogTitle>
          <DialogDescription>
            {t("devices.scale.add.description")}
          </DialogDescription>
        </DialogHeader>
        <Label>{t("devices.scale.choose_connection_method")}</Label>
        <Tabs onValueChange={(type) => setConnectionType(type)}>
          
          <TabsList>
            {connectionTypes.map((connection_types) => {
              return (
                <TabsTrigger
                  key={connection_types.key}
                  value={connection_types.value}
                  disabled={connection_types.disabled}
                >
                  {connection_types.name}
                </TabsTrigger>
              );
            })}
          </TabsList>
        </Tabs>
        <div>
          <Label>{t("devices.scale.name")}</Label>
          <Input
            value={name}
            onChange={(ev) => setName(ev.target.value)}
            disabled={connectionType === "bluetooth"}
            placeholder={t("devices.scale.name.placeholder")}
          />
        </div>
        <div>
          <Label>{t("devices.scale.vendor")}</Label>
          <Select onValueChange={(value) => setVendor(value)}>
            <SelectTrigger>
              <SelectValue placeholder={t("devices.scale.vendor_select")} />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>{t("devices.scale.vendor")}</SelectLabel>
                {scaleVendors.map((vendor) => {
                  return (
                    <SelectItem key={vendor.id} value={vendor}>
                      {vendor.name}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        {vendor && scaleModels && (
          <div>
            <Label>{t("devices.scale.model")}</Label>
            <Select value={model} onValueChange={(model) => setModel(model)}>
              <SelectTrigger>
                <SelectValue placeholder={t("devices.scale.model_select")} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>{t("devices.scale.model")}</SelectLabel>
                  {scaleModels.map((model) => {
                    return (
                      <SelectItem key={model.id} value={model}>
                        {model.name}
                      </SelectItem>
                    );
                  })}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        )}
        {connectionType === "bluetooth" && (
          <Button onClick={handleScan} disabled={!model}>
            {t("devices.scale.scan")}
          </Button>
        )}
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">{t("common.button.cancel")}</Button>
          </DialogClose>
          <Button
            disabled={shouldDisableSaveButton()}
            variant="primary"
            onClick={handleAdd}
          >
            {t("common.button.add")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddScaleDialog;
