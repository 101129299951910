import WeighingScale from './WeighingScale';

export class CountingScale extends WeighingScale {
    constructor() {
        super();

        this._singleWeight = 0;
        this._singleWeightUnit = 'g';
        this._quantity = 0;
    }

    get singleWeight() {
        return this._singleWeight;
    }

    get singleWeightUnit() {
        return this._singleWeightUnit;
    }

    get quantity() {
        return this._quantity;
    }
}

export default CountingScale;