import React from "react";

export const DeviceContext = React.createContext(null);

export const DeviceProvider = ({ children }) => {
  const [devices, setDevices] = React.useState({
    scales: [],
    printers: [],
  });

  return (
    <DeviceContext.Provider
      value={{
        devices,
        setDevices,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};

export const useDevice = () => {
  const context = React.useContext(DeviceContext);

  if (context === undefined) {
    throw new Error("useDevice must be used within a DeviceProvider");
  }

  return context;
};
