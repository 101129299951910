import { Model } from "@nozbe/watermelondb";
import {
  field,
  relation,
  readonly,
  date,
} from "@nozbe/watermelondb/decorators";

export default class MeasurementField extends Model {
  static table = "measurement_fields";
  static associations = {
    measurements: { type: "belongs_to", key: "measurement_id" },
  };

  @field("measurement_id") measurementId;
  @field("field_name") fieldName;
  @field("field_value") fieldValue;

  @relation("measurements", "measurement_id") measurement;

  @readonly @date("created_at") createdAt;
  @readonly @date("updated_at") updatedAt;
}
