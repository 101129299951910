import { Model } from "@nozbe/watermelondb";
import {
  field,
  children,
  readonly,
  date,
} from "@nozbe/watermelondb/decorators";

export default class ScaleVendor extends Model {
  static table = "scale_vendors";
  static associations = {
    scale_models: { type: "has_many", foreignKey: "vendor_id" },
  };

  @field("name") name;

  @children("scale_models") models;

  @readonly @date("created_at") createdAt;
  @readonly @date("updated_at") updatedAt;
}
