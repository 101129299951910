import { Model } from "@nozbe/watermelondb";
import {
  field,
  readonly,
  date,
  relation,
} from "@nozbe/watermelondb/decorators";

export default class InventoryItemField extends Model {
    static table = "inventory_item_fields";
    static associations = {
      inventory_items: { type: "belongs_to", key: "item_id" },
    };
  
    @field("item_id") itemId;
    @field("field_name") fieldName;
    @field("field_value") fieldValue;
    @relation("inventory_items", "item_id") item;
  
    @readonly @date("created_at") createdAt;
    @readonly @date("updated_at") updatedAt;
  }