import React, { useState, useEffect, useRef } from "react";

export const useDataScanner = (delimiter, bufferSize) => {
  const [jsonData, setJsonData] = useState(null);
  const [error, setError] = useState(null);

  const bufferRef = useRef("");

  React.useEffect(() => {

    const processBuffer = () => {
      console.log("Processing buffer...");
      const extractedData = extractData(bufferRef.current, delimiter);
      console.log("Extracted data:", extractedData);
      if (extractedData) {
        try {
          const data = JSON.parse(extractedData);
          setJsonData(data);
          setError(null);
        } catch (error) {
          setError("Invalid JSON data");
          setJsonData(null);
        } finally {
          bufferRef.current = "";
        }
      }
    };

    const handleKeyDown = (event) => {
      const key = event.key;
      bufferRef.current += key;

      if (bufferRef.current.length > bufferSize) {
        // Remove the oldest character if the buffer is full
        bufferRef.current = bufferRef.current.slice(1);
      }

      console.log("Buffer:", bufferRef.current);

      processBuffer();
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [delimiter, bufferSize]);

  const extractData = (scannedData, delimiter) => {
    const regex = new RegExp(`${delimiter}(.*?)${delimiter}`, "s");
    const match = scannedData.match(regex);
    console.log("Match:", match);
    return match ? (match[1] === "" ? "EMPTY" : match[1]) : "";
  };

  return { jsonData, error };
};
