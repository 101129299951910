import { Model } from "@nozbe/watermelondb";
import {
  field,
  relation,
  readonly,
  date,
} from "@nozbe/watermelondb/decorators";

export default class Printer extends Model {
  static table = "printers";

  @field("device_id") deviceId;
  @field("printer_type") printerType;
  @field("paper_width") paperWidth;
  @field("paper_height") paperHeight;
  @field("is_continuous_paper") isContinuousPaper;

  @relation("printer_models", "model_id") model;
  @relation("devices", "device_id") device;

  @readonly @date("created_at") createdAt;
  @readonly @date("updated_at") updatedAt;
}
