import Scale from "./pages/Scale";
import Records from "./pages/Records";
import Settings from "./pages/Settings";
import Devices from "./pages/Devices";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import AccountSettings, { Profile } from "./pages/AccountSettings";
import Inventory from "./pages/Inventory";

import ProtectedRoute from "./components/ProtectedRoute";
import { Navigate } from "react-router-dom";

import {
  ScaleIcon,
  TablePropertiesIcon,
  SettingsIcon,
  RadioReceiverIcon,
  HomeIcon,
  FolderKanbanIcon
} from "lucide-react";

const mainRoutes = [
  {
    path: "home",
    element: <Home />,
    handle: {
      name: "route.home.name",
      description: "route.home.description",
      icon: HomeIcon,
      position: "top",
    },
  },
  {
    path: "scale",
    element: <Scale />,
    handle: {
      name: "route.scale.name",
      description: "route.scale.description",
      icon: ScaleIcon,
      position: "top",
    },
  },
  {
    path: "inventory",
    element: <Inventory />,
    handle: {
      name: "route.inventory.name",
      description: "route.inventory.description",
      icon: FolderKanbanIcon,
      position: "top",
    },
  },
  {
    path: "records",
    element: <Records />,
    handle: {
      name: "route.records.name",
      description: "route.records.description",
      icon: TablePropertiesIcon,
      position: "top",
    },
  },
  {
    path: "devices",
    element: <Devices />,
    handle: {
      name: "route.devices.name",
      description: "route.devices.description",
      icon: RadioReceiverIcon,
      position: "top",
    },
    children: [
      {
        path: "scale",
        handle: {
          name: "route.devices.scale.name",
          description: "route.devices.scale.description",
        },
        children: [{
          path: "connect",
          handle: {
            name: "route.devices.scale.connect.name",
            description: "route.devices.scale.connect.description",
          },
        }],
      },
      {
        path: "printer",
        handle: {
          name: "route.devices.printer.name",
          description: "route.devices.printer.description",
        },
        children: [
          {
            path: "connect",
            handle: {
              name: "route.devices.printer.connect.name",
              description: "route.devices.printer.connect.description",
            },
          }
        ]
      }
    ],
  },
  {
    path: "settings",
    element: <Settings />,
    handle: {
      name: "route.settings.name",
      description: "route.settings.description",
      icon: SettingsIcon,
      position: "bottom",
    },
  },
  {
    path: "account",
    element: <AccountSettings />,
    handle: {
      name: "route.account.name",
      description: "route.account.description",
    },
    children: [
      {
        path: "profile",
        element: <Profile />,
        handle: {
          name: "route.account.profile.name",
          description: "route.account.profile.description",
        },
      },
      {
        path: "appearance",
        handle: {
          name: "route.account.appearance.name",
          description: "route.account.appearance.description",
        },
      }
    ]
  },
];

export const routes = [
  {
    path: "/login",
    element: <Login />,
    handle: {
      name: "route.login.name",
      description: "route.login.description",
    },
  },
  {
    path: "/signup",
    element: <Signup />,
    handle: {
      name: "route.signup.name",
      description: "route.signup.description",
    },
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    handle: {
      name: "route.forgot-password.name",
      description: "route.forgot-password.description",
    },
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
    handle: {
      name: "route.reset-password.name",
      description: "route.reset-password.description",
    },
  },
  {
    path: "/",
    element: <ProtectedRoute />,
    handle: {
      name: "route.layout.name",
      description: "route.layout.description",
      mainRoutes: mainRoutes,
    },
    children: [
      { index: true, element: <Navigate to={mainRoutes[0].path} /> },
      ...mainRoutes,
    ],
  },
];

export default routes;
