import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { PlusCircle, Trash, Link, Unlink } from "lucide-react";
import React from "react";
import { useTranslation } from "react-i18next";
import AddScaleDialog from "./AddScaleDialog";
import { useDatabase } from "@/contexts/DatabaseContext/DatabaseContext";
import {
  Tooltip,
  TooltipProvider,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

import BluetoothAdapter from "@/drivers/adapters/Bluetooth";
import DNC from "@/drivers/scales/dnc";
import { useDevice } from "@/contexts/DeviceContext";
import { useToast } from "@/components/ui/use-toast";

const bluetoothDriverFactory = async (scale, deviceModel) => {
  if (!scale || !deviceModel) {
    console.error("Scale or device model is not provided");
    return null;
  }

  // the scale model here means the machine model
  const scaleModel = await scale.model;
  if (!scaleModel) {
    console.error("Scale model is not provided");
    return null;
  }

  let device;
  let serviceUuid = scaleModel.bleServiceUuid;
  let characteristicUuid = scaleModel.bleCharUuid;
  if (navigator.bluetooth && navigator.bluetooth.getDevices) {
    const devices = await navigator.bluetooth.getDevices();

    if (serviceUuid.startsWith("0x")) {
      serviceUuid = parseInt(serviceUuid, 16);
    }

    if (characteristicUuid.startsWith("0x")) {
      characteristicUuid = parseInt(characteristicUuid, 16);
    }

    device = devices.find((device) => device.name === deviceModel.name);
  } else {
    console.warn("Bluetooth API is not supported");
    return null;
  }
  if (!device) {
    console.warn("Device not found, trying to connect to a new device");

    try {
      device = await navigator.bluetooth.requestDevice({
        filters: [{ services: [serviceUuid] }],
        optionalServices: [parseInt("0xfff1", 16)],
      });
    } catch (error) {
      console.error("Error requesting device", error);
      return null;
    }

    if (!device) {
      console.error("Device not found");
      return null;
    }
  }

  const adapter = new BluetoothAdapter(device);

  if (scaleModel.name.startsWith("DNC")) {
    return new DNC(adapter);
  }
};

const driverFactory = async (scaleModel) => {
  if (!scaleModel) {
    return null;
  }

  const deviceModel = await scaleModel.device;
  if (!deviceModel) {
    return null;
  }

  if (deviceModel.connectionType === "bluetooth") {
    return bluetoothDriverFactory(scaleModel, deviceModel);
  }

  return null;
};

export const ScalesCard = () => {
  const { t } = useTranslation();
  const { database } = useDatabase();
  const [openAddScaleDialog, setOpenAddScaleDialog] = React.useState(false);
  const [scales, setScales] = React.useState([]); // Add scales state here
  const refreshTimerRef = React.useRef(null);
  const { setDevices, devices: allDevices } = useDevice();
  const [loading, setLoading] = React.useState(false);
  const { toast } = useToast();

  const fetchScales = React.useCallback(async () => {
    if (!database) {
      toast({
        title: t("common.error"),
        description: t("devices.scale.fetch_error_description"),
        variant: "destructive",
      });
      return;
    }

    try {
      setLoading(true);
      const scaleRecords = await database.get("scales").query().fetch();
      const scales = [];

      for (const scale of scaleRecords) {
        const device = await scale.device;
        const model = await scale.model;
        const vendor = await model.vendor;
        const connectedDevice = allDevices.scales.find(
          (d) => d.adapter.device.name === device.name
        );
        scales.push({
          id: scale.id,
          name: device.name,
          connectionType: device.connectionType,
          vendor: vendor?.name || "-",
          model: model?.name || "-",
          status: connectedDevice
            ? connectedDevice.adapter.connection_status
            : "disconnected",
        });
      }

      setScales(scales);
    } catch (error) {
      console.error("Error fetching scales", error);
      toast({
        title: t("common.error"),
        description: t("devices.scale.fetch_error_description"),
        variant: "destructive",
      });
      setScales([]);
    } finally {
      setLoading(false);
    }
  }, [database, allDevices.scales, setScales, setLoading, toast, t]);

  const handleDeviceConnect = async (scale) => {
    try {
      const scaleRecord = await database.get("scales").find(scale.id);

      const driver = await driverFactory(scaleRecord);
      if (!driver) {
        console.error("Driver not found");
        return;
      }
      await driver.connect();
      setDevices((devices) => {
        return {
          ...devices,
          scales: [...devices.scales, driver],
        };
      });
    } catch (error) {
      console.error("Error connecting device", error);
      toast({
        title: t("common.error"),
        description: t("devices.scale.connect_error_description"),
        variant: "destructive",
      });
    } finally {
      setLoading(false);
      fetchScales();
    }
  };

  const handleDeviceDelete = async (scale) => {
    const confirmDelete = window.confirm(t("devices.scale.delete_confirmation_message"));
    
    if (!confirmDelete) return;
  
    try {
      setLoading(true);
      const scaleRecord = await database.get("scales").find(scale.id);
      const device = await scaleRecord.device;
  
      // Check if the device is connected
      const connectedDevice = allDevices.scales.find(
        (d) => d.adapter.device.name === device.name
      );
  
      // Disconnect if connected
      if (connectedDevice) {
        await connectedDevice.disconnect();
        setDevices((devices) => ({
          ...devices,
          scales: devices.scales.filter(
            (d) => d.adapter.device.name !== device.name
          ),
        }));
      }
  
      // Forget the device if it's Bluetooth
      if (device.connectionType === "bluetooth") {
        try {
          await navigator.bluetooth.forget(device.name);
        } catch (error) {
          console.warn("Error forgetting Bluetooth device", error);
        }
      }
  
      // Delete the scale and device from the database
      await database.write(async () => {
        await scaleRecord.markAsDeleted();
        await device.markAsDeleted();
      });
  
      toast({
        title: t("common.success"),
        description: t("devices.scale.delete_success_description"),
      });
    } catch (error) {
      console.error("Error deleting scale", error);
      toast({
        title: t("common.error"),
        description: t("devices.scale.delete_error_description"),
        variant: "destructive",
      });
    } finally {
      setLoading(false);
      fetchScales();
    }
  };

  const handleDeviceDisconnect = async (scale) => {
    try {
      setLoading(true);
      const scaleRecord = await database.get("scales").find(scale.id);
      const device = await scaleRecord.device;
      const connectedDevice = allDevices.scales.find(
        (d) => d.adapter.device.name === device.name
      );

      if (!connectedDevice) {
        console.error("Device not connected");
        return;
      }

      await connectedDevice.disconnect();
      setDevices((devices) => {
        return {
          ...devices,
          scales: devices.scales.filter(
            (d) => d.adapter.device.name !== device.name
          ),
        };
      });
    } catch (error) {
      console.error("Error disconnecting device", error);
      toast({
        title: t("common.error"),
        description: t("devices.scale.disconnect_error_description"),
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    refreshTimerRef.current = setInterval(fetchScales, 1000);
    return () => {
      if (refreshTimerRef.current) clearInterval(refreshTimerRef.current);
      refreshTimerRef.current = null;
    };
  }, [fetchScales]);

  const columns = [
    {
      key: t("devices.table.name"),
      name: t("devices.table.name"),
      className: "text-left", // Add className field here
    },
    {
      key: t("devices.table.connection_type"),
      name: t("devices.table.connection_type"),
      className: "text-left", // Add className field here
    },
    {
      key: t("devices.table.vendor"),
      name: t("devices.table.vendor"),
      className: "text-left", // Add className field here
    },
    {
      key: t("devices.table.model"),
      name: t("devices.table.model"),
      className: "text-left", // Add className field here
    },
    {
      key: t("devices.table.status"),
      name: t("devices.table.status"),
      className: "text-left", // Add className field here
    },
    {
      key: t("devices.table.action"),
      name: t("devices.table.action"),
      className: "text-left", // Add className field here
    },
  ];

  return (
    <>
      <Card>
        <CardHeader>
          <div className={"flex flex-row justify-between"}>
            <div>
              <CardTitle>{t("devices.scale")}</CardTitle>
              <CardDescription>
                {t("devices.scale.description")}
              </CardDescription>
            </div>
            <div className={"flex flex-row justify-end"}>
              <Button
                className={"gap-2"}
                onClick={() => {
                  setOpenAddScaleDialog(true);
                }}
                disabled={loading}
              >
                <PlusCircle />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                  {t("devices.scale.add")}
                </span>
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                {columns.map((column) => (
                  <TableHead key={column.key} className={column.className}>
                    {column.name}
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {scales.map((scale) => (
                <TableRow key={scale.id}>
                  <TableCell>{scale.name}</TableCell>
                  <TableCell>
                    {t(`devices.scale.connection_type.${scale.connectionType}`)}
                  </TableCell>
                  <TableCell>{scale.vendor}</TableCell>
                  <TableCell>{scale.model}</TableCell>
                  <TableCell>{t(`scale.status.${scale.status}`)}</TableCell>
                  <TableCell className={"flex gap-x-1"}>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            variant="outline"
                            size="icon"
                            onClick={
                              scale.status === "disconnected"
                                ? () => handleDeviceConnect(scale)
                                : () => handleDeviceDisconnect(scale)
                            }
                            disabled={loading}
                          >
                            {scale.status === "disconnected" ? (
                              <Link className="h-4 w-4" />
                            ) : (
                              <Unlink className="h-4 w-4" />
                            )}
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          {scale.status === "disconnected"
                            ? t("devices.scale.connect")
                            : t("devices.scale.disconnect")}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            variant="outline"
                            size="icon"
                            onClick={() => handleDeviceDelete(scale)}
                            disabled={loading}
                          >
                            <Trash className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          {t("devices.scale.delete")}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </TableCell>
                </TableRow>
              ))}
              {scales.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className={"text-center text-slate-600/70"}
                  >
                    {t("devices.scale.no_scales")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
        <CardFooter></CardFooter>
      </Card>
      <AddScaleDialog
        open={openAddScaleDialog}
        setOpen={setOpenAddScaleDialog}
      />
    </>
  );
};
