import React from "react";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useDevice } from "@/contexts/DeviceContext";

import { Link } from "@radix-ui/themes";
import { Link as ReactRouterLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useDatabase } from "@/contexts/DatabaseContext/DatabaseContext";
import { Q } from "@nozbe/watermelondb";

export const Home = () => {
  const { t } = useTranslation();
  const { devices } = useDevice();
  const { database } = useDatabase();
  const [connectedScale, setConnectedScale] = React.useState(null);
  const user = "guest";

  React.useEffect(() => {
    const fetchConnectedScaleInfo = async () => {
      if (devices.scales.length > 0) {
        const connectedDeviceName = devices.scales[0].adapter.device.name;
        const deviceModel = await database
          .get("devices")
          .query(Q.where("name", connectedDeviceName), Q.take(1))
          .fetch();

        if (deviceModel.length > 0) {
          const scale = (await deviceModel[0].scales)[0];
          const model = await scale.model;
          if (scale) {
            setConnectedScale({
              device: deviceModel[0],
              scale: scale,
              model: model,
              vendor: await model.vendor,
            });
          }
        }
      } else {
        setConnectedScale(null);
      }
    };

    fetchConnectedScaleInfo();
  }, [devices]);

  console.log()

  return (
    <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-8">
      <Card className="sm:col-span-1 md:col-span-2">
        <CardHeader>
          <CardTitle>
            <Trans i18nKey="home.welcome.title" user={"Frank"}>
              Welcome back {{ user }}!
            </Trans>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Trans i18nKey="home.welcome.message">
            Welcome to ScaleConnect! Effortlessly integrate your commercial
            weighing or counting scale with our user-friendly app. Experience
            seamless Bluetooth connectivity, real-time data syncing, and
            enhanced efficiency for your business operations.
          </Trans>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>{t("home.scale.status.card.title")}</CardTitle>
          <CardDescription>
            {t("home.scale.status.card.description")}
          </CardDescription>
        </CardHeader>
        <CardContent>
          {/* no scale connected */}
          {connectedScale ? (
            <div className="flex flex-col">
              <div className="flex flex-row gap-12">
                <div>{t("devices.scale.name")}</div>
                <div>{connectedScale.device.name}</div>
              </div>
              <div className="flex flex-row gap-12">
                <div>{t("devices.scale.model")}</div>
                <div>{connectedScale.model.name}</div>
              </div>
              <div className="flex flex-row gap-12">
                <div>{t("devices.scale.vendor")}</div>
                <div>{connectedScale.vendor.name}</div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-32">
              <p className="text-muted-foreground">
                {t("home.scale.status.card.no_scale")}
              </p>
              <p>
                <Link asChild>
                  <ReactRouterLink to={"/devices/scale/connect"}>
                    {t("home.printer.status.card.connect_scale")}
                  </ReactRouterLink>
                </Link>
              </p>
            </div>
          )}
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>{t("home.printer.status.card.title")}</CardTitle>
          <CardDescription>
            {t("home.printer.status.card.description")}
          </CardDescription>
        </CardHeader>
        <CardContent>
          {/* no printer connected */}
          <div className="flex flex-col items-center justify-center h-32">
            <p className="text-muted-foreground">
              {t("home.printer.status.card.no_printer")}
            </p>
            <p>
              <Link asChild>
                <ReactRouterLink to={"/devices/printer/connect"}>
                  {t("home.printer.status.card.connect_printer")}
                </ReactRouterLink>
              </Link>
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Home;
