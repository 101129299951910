import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { Separator } from "@/components/ui/separator";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import dayjs from "dayjs";
import { Copy, Download, MoreVertical, Scale, Trash2 } from "lucide-react";
import React from "react";
import { Checkbox } from "@/components/ui/checkbox";

import DateTimeRangePicker from "@/components/DateTimeRangePicker";
import { useToast } from "@/components/ui/use-toast";
import { useDatabase } from "@/contexts/DatabaseContext/DatabaseContext";
import { Q } from "@nozbe/watermelondb";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { useTranslation } from "react-i18next";

const EmptyState = ({ onAddNew }) => {
  const { t } = useTranslation();
  return (
    <div className="text-center py-12">
      <Scale className="mx-auto h-12 w-12 text-muted-foreground" />
      <h3 className="mt-4 text-lg font-semibold">
        {t("records.no_measurements")}
      </h3>
      <p className="mt-2 text-muted-foreground">
        {t("records.no_measurements_description")}
      </p>
      <Button onClick={onAddNew} className="mt-4">
        {t("common.add_new")}
      </Button>
    </div>
  );
};

const SkeletonLoader = () => (
  <div className="space-y-4">
    {[...Array(5)].map((_, i) => (
      <div key={i} className="h-12 bg-muted animate-pulse rounded" />
    ))}
  </div>
);

const MeasurementTable = ({
  measurements,
  onSelectMeasurement,
  selectedId,
  onDeleteMeasurement,
  currentPage,
  totalCount,
  itemsPerPage,
  onPageChange,
  isLoading,
  dateTimeRange,
  setDateTimeRange,
  searchTerm,
  setSearchTerm,
  setSortConfig,
  sortConfig,
  fetchMeasurements
}) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { database } = useDatabase();
  const [selectedRows, setSelectedRows] = React.useState(new Set());
  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Measurements");

    // Add headers
    const headers = [
      t("scale.item_details.item_no"),
      t("scale.item_details.item_name"),
      t("scale.total_weight"),
      t("scale.tare_weight"),
      t("scale.net_weight"),
      t("scale.single_weight"),
      t("devices.scale.name"),
      t("devices.scale.model"),
      t("devices.scale.vendor"),
      t("records.recorded_on"),
    ];
    worksheet.addRow(headers);

    // Fetch all measurements
    const measurementsCollection = database.get("measurements");
    let query = measurementsCollection.query(
      Q.sortBy(sortConfig.key, sortConfig.direction === "asc" ? Q.asc : Q.desc),
    );

    if (dateTimeRange?.from) {
      const start = dayjs(dateTimeRange.from).startOf("day").toDate();
      const end = dateTimeRange.to
        ? dayjs(dateTimeRange.to).endOf("day").toDate()
        : dayjs(dateTimeRange.from).endOf("day").toDate();
      query = query.extend(
        Q.where("timestamp", Q.between(start.getTime(), end.getTime())),
      );
    }

    if (searchTerm) {
      query = query.extend(
        Q.or(
          Q.where("productNo", Q.like(`%${Q.sanitizeLikeString(searchTerm)}%`)),
          Q.where("name", Q.like(`%${Q.sanitizeLikeString(searchTerm)}%`)),
        ),
      );
    }

    const allMeasurements = await query.fetch();

    // Add data
    for (const measurement of allMeasurements) {
      const scaleInfo = await measurement.scale.fetch();
      const device = await scaleInfo.device.fetch();
      const model = await scaleInfo.model.fetch();
      const vendor = await model.vendor.fetch();
      const measurementFields = await measurement.measurementFields.fetch();

      const row = [
        measurement.productNo,
        measurement.name,
        `${measurement.totalWeight} ${measurement.totalUnit}`,
        `${measurement.tareWeight} ${measurement.tareUnit}`,
        `${measurement.netWeight} ${measurement.netUnit}`,
        `${measurement.singleWeight} ${measurement.singleUnit}`,
        device.name || t("common.unknown"),
        model.name || t("common.unknown"),
        vendor.name || t("common.unknown"),
        measurement.createdAt.toLocaleString(),
      ];

      // Add measurement fields
      measurementFields.forEach((field) => {
        if (!headers.includes(field.fieldName)) {
          headers.push(field.fieldName);
          worksheet.getRow(1).getCell(headers.length).value = field.fieldName;
        }
        row[headers.indexOf(field.fieldName)] = field.fieldValue;
      });

      worksheet.addRow(row);
    }

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(blob, "records.xlsx");
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const handleDelete = (measurement, event) => {
    event.stopPropagation();
    if (window.confirm(t("records.confirm_delete"))) {
      onDeleteMeasurement(measurement)
        .then(() => {
          toast({
            title: t("common.success"),
            description: t("records.success.measurement_deleted"),
          });
        })
        .catch((error) => {
          console.error("Failed to delete measurement:", error);
          toast({
            title: t("common.error"),
            description: t("records.error.failed_deleting_measurement"),
            variant: "destructive",
          });
        });
    }
  };

  const clearDateFilter = () => {
    setDateTimeRange(null);
  };

  const handleRowSelection = (measurementId) => {
    setSelectedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(measurementId)) {
        newSet.delete(measurementId);
      } else {
        newSet.add(measurementId);
      }
      return newSet;
    });
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedRows(new Set(measurements.map((m) => m.id)));
    } else {
      setSelectedRows(new Set());
    }
  };

  const handleBulkDelete = async () => {
    if (window.confirm(t("records.confirm_bulk_delete"))) {
      try {
        const measurementsToBeDeleted = measurements.filter((m) =>
          selectedRows.has(m.id))

        for (const measurement of measurementsToBeDeleted) {
          await onDeleteMeasurement(measurement);
        }

        toast({
          title: t("common.success"),
          description: t("records.success.measurements_deleted"),
        });
        setSelectedRows(new Set());
        await fetchMeasurements(currentPage);
      } catch (error) {
        console.error("Failed to delete measurements:", error);
        toast({
          title: t("common.error"),
          description: t("records.error.failed_deleting_measurements"),
          variant: "destructive",
        });
      }
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("records.measurements")}</CardTitle>
        <CardDescription>
          {t("records.measurements_description")}
        </CardDescription>
        <div className="flex flex-col sm:flex-row gap-4">
          <Input
            placeholder={t("common.search")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <DateTimeRangePicker
            dateTimeRange={dateTimeRange}
            setDateTimeRange={setDateTimeRange}
            clearDateTimes={clearDateFilter}
          />
          <Button onClick={exportToExcel} className="ml-auto">
            <Download className="mr-2 h-4 w-4" />
            {t("common.export")}
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <SkeletonLoader />
        ) : measurements.length > 0 ? (
          <>
            {selectedRows.size > 0 && (
              <div className="mb-4 p-2 bg-muted rounded flex justify-between items-center">
                <span>
                  {t("records.selected", { count: selectedRows.size })}
                </span>
                <Button onClick={handleBulkDelete} variant="destructive">
                  {t("common.delete")}
                </Button>
              </div>
            )}
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[50px]">
                    <Checkbox
                      checked={selectedRows.size === measurements.length ? true : selectedRows.size !== 0 ? "indeterminate": false}
                      onCheckedChange={handleSelectAll}
                    />
                  </TableHead>
                  <TableHead onClick={() => handleSort("product_no")}>
                    {t("scale.item_details.item_no")}
                  </TableHead>
                  <TableHead onClick={() => handleSort("name")}>
                    {t("scale.item_details.item_name")}
                  </TableHead>
                  <TableHead onClick={() => handleSort("net_weight")}>
                    {t("scale.net_weight")}
                  </TableHead>
                  <TableHead onClick={() => handleSort("total_weight")}>
                    {t("scale.total_weight")}
                  </TableHead>
                  <TableHead>{t("common.date")}</TableHead>
                  <TableHead>{t("common.actions")}</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {measurements.map((measurement) => (
                  <TableRow
                    key={measurement.id}
                    onClick={() => onSelectMeasurement(measurement)}
                    className={selectedId === measurement.id ? "bg-muted" : ""}>
                                          <TableCell>
                      <Checkbox
                        checked={selectedRows.has(measurement.id)}
                        onCheckedChange={() => handleRowSelection(measurement.id)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </TableCell>
                    <TableCell>{measurement.productNo}</TableCell>
                    <TableCell>{measurement.name}</TableCell>
                    <TableCell>{`${measurement.netWeight} ${measurement.netUnit}`}</TableCell>
                    <TableCell>{`${measurement.totalWeight} ${measurement.totalUnit}`}</TableCell>
                    <TableCell>
                      {new Date(measurement.timestamp).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <Button
                        size="icon"
                        variant="ghost"
                        onClick={(e) => handleDelete(measurement, e)}>
                        <Trash2 className="h-4 w-4" />
                        <span className="sr-only">{t("common.delete")}</span>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="mt-4">
              <Pagination>
                <PaginationContent>
                  <PaginationItem>
                    <PaginationPrevious
                      onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
                      disabled={currentPage === 1}
                    />
                  </PaginationItem>
                  {[...Array(totalPages)].map((_, index) => (
                    <PaginationItem key={index}>
                      <PaginationLink
                        onClick={() => onPageChange(index + 1)}
                        isActive={currentPage === index + 1}>
                        {index + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem>
                    <PaginationNext
                      onClick={() =>
                        onPageChange(Math.min(currentPage + 1, totalPages))
                      }
                      disabled={currentPage === totalPages}
                    />
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            </div>
          </>
        ) : (
          <EmptyState
            onAddNew={() => {
              /* Add new measurement logic */
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};

const MeasurementDetail = ({ measurement, onDelete }) => {
  const { t } = useTranslation();
  const [scaleInfo, setScaleInfo] = React.useState(null);
  const [measurementFields, setMeasurementFields] = React.useState([]);

  const handleDelete = () => {
    if (window.confirm(t("records.confirm_delete"))) {
      onDelete(measurement);
    }
  };

  React.useEffect(() => {
    if (!measurement) return;

    /* fetch scale info */
    const fetchScaleInfo = async () => {
      // Fetch scale info
      const scale = await measurement.scale;

      if (!scale) {
        setScaleInfo(null);
        return;
      }

      const device = await scale.device;
      const model = await scale.model;
      const vendor = await model.vendor;

      setScaleInfo({
        scale,
        device,
        model,
        vendor,
      });
    };

    fetchScaleInfo();
  }, [measurement]);

  React.useEffect(() => {
    if (!measurement) return;

    /* fetch measurement fields */
    const fetchMeasurementFields = async () => {
      const fields = await measurement.measurementFields.fetch();
      setMeasurementFields(fields);
    };

    fetchMeasurementFields();
  }, [measurement]);

  if (!measurement)
    return (
      <Card className="p-6 text-center text-muted">
        <CardHeader>
          <CardDescription>{t("records.select_measurement")}</CardDescription>
        </CardHeader>
      </Card>
    );

  return (
    <Card>
      <CardHeader className="flex flex-row items-start bg-muted/50">
        <div className="grid gap-0.5">
          <CardTitle className="group flex items-center gap-2 text-lg">
            {t("records.record")} {measurement.id}
            <Button
              size="icon"
              variant="outline"
              className="h-6 w-6 opacity-0 transition-opacity group-hover:opacity-100">
              <Copy className="h-3 w-3" />
              <span className="sr-only">{t("common.copy_id")}</span>
            </Button>
          </CardTitle>
          <CardDescription>
            {t("common.date")}:{" "}
            {new Date(measurement.timestamp).toLocaleString()}
          </CardDescription>
        </div>
        <div className="ml-auto flex items-center gap-1">
          <Button size="sm" variant="outline" className="h-8 gap-1">
            <Scale className="h-3.5 w-3.5" />
            <span className="lg:sr-only xl:not-sr-only xl:whitespace-nowrap">
              {t("scale.view_scale")}
            </span>
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="icon" variant="outline" className="h-8 w-8">
                <MoreVertical className="h-3.5 w-3.5" />
                <span className="sr-only">{t("common.more")}</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem>{t("common.edit")}</DropdownMenuItem>
              <DropdownMenuItem>{t("common.export")}</DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={handleDelete}
                className="text-destructive">
                {t("common.delete")}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </CardHeader>
      <CardContent className="p-6 text-sm">
        <div className="grid gap-3">
          <div className="font-semibold">{t("scale.item_details")}</div>
          <dl className="grid gap-3">
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">
                {t("scale.item_details.item_no")}
              </dt>
              <dd>{measurement.productNo}</dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">
                {t("scale.item_details.item_name")}
              </dt>
              <dd>{measurement.name}</dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">
                {t("scale.total_weight")}
              </dt>
              <dd>{`${measurement.totalWeight} ${measurement.totalUnit}`}</dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">
                {t("scale.tare_weight")}
              </dt>
              <dd>{`${measurement.tareWeight} ${measurement.tareUnit}`}</dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">{t("scale.net_weight")}</dt>
              <dd>{`${measurement.netWeight} ${measurement.netUnit}`}</dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">
                {t("scale.single_weight")}
              </dt>
              <dd>{`${measurement.singleWeight} ${measurement.singleUnit}`}</dd>
            </div>
            {Boolean(measurement.price) && (
              <div className="flex items-center justify-between">
                <dt className="text-muted-foreground">{t("scale.price")}</dt>
                <dd>{`${measurement.price} ${measurement.priceUnit}`}</dd>
              </div>
            )}
            {measurementFields.map((field) => (
              <div key={field.id} className="flex items-center justify-between">
                <dt className="text-muted-foreground">{field.fieldName}</dt>
                <dd>{field.fieldValue}</dd>
              </div>
            ))}
          </dl>
        </div>
        <Separator className="my-4" />
        <div className="grid gap-3">
          <div className="font-semibold">{t("scale.scale_information")}</div>
          <dl className="grid gap-3">
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">
                {t("devices.scale.name")}
              </dt>
              <dd>{scaleInfo?.device.name ?? t("common.unknown")}</dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">
                {t("devices.scale.model")}
              </dt>
              <dd>{scaleInfo?.model.name ?? t("common.unknown")}</dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">
                {t("devices.scale.vendor")}
              </dt>
              <dd>{scaleInfo?.vendor.name ?? t("common.unknown")}</dd>
            </div>
          </dl>
        </div>
      </CardContent>
      <CardFooter className="flex flex-row items-center border-t bg-muted/50 px-6 py-3">
        <div className="text-xs text-muted-foreground">
          {t("records.recorded_on")}{" "}
          <time dateTime={measurement.createdAt.toISOString()}>
            {measurement.createdAt.toLocaleString()}
          </time>
        </div>
      </CardFooter>
    </Card>
  );
};

export const Records = () => {
  const { database } = useDatabase();
  const [measurements, setMeasurements] = React.useState([]);
  const [selectedMeasurement, setSelectedMeasurement] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [totalCount, setTotalCount] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;
  const { t } = useTranslation();
  const { toast } = useToast();
  const [dateTimeRange, setDateTimeRange] = React.useState(null);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [sortConfig, setSortConfig] = React.useState({
    key: "timestamp",
    direction: "desc",
  });

  const fetchMeasurements = React.useCallback(
    async (page = 1) => {
      setIsLoading(true);
      try {
        const measurementsCollection = database.get("measurements");
        let query = measurementsCollection.query(
          Q.sortBy(
            sortConfig.key,
            sortConfig.direction === "asc" ? Q.asc : Q.desc,
          ),
        );

        if (dateTimeRange?.from) {
          const start = dayjs(dateTimeRange.from).startOf("day").toDate();
          const end = dateTimeRange.to
            ? dayjs(dateTimeRange.to).endOf("day").toDate()
            : dayjs(dateTimeRange.from).endOf("day").toDate();
          query = query.extend(
            Q.where("timestamp", Q.between(start.getTime(), end.getTime())),
          );
        }

        if (searchTerm) {
          query = query.extend(
            Q.or(
              Q.where(
                "product_no",
                Q.like(`%${Q.sanitizeLikeString(searchTerm)}%`),
              ),
              Q.where("name", Q.like(`%${Q.sanitizeLikeString(searchTerm)}%`)),
            ),
          );
        }

        const count = await query.fetchCount();
        setTotalCount(count);

        const offset = (page - 1) * itemsPerPage;
        const fetchedMeasurements = await query
          .extend(Q.skip(offset), Q.take(itemsPerPage))
          .fetch();

        setMeasurements(fetchedMeasurements);
        setCurrentPage(page);
      } catch (err) {
        console.error("Failed to fetch measurements:", err);
        toast({
          title: t("common.error"),
          description: t("records.error.failed_fetching_measurements"),
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [database, t, toast, itemsPerPage, dateTimeRange, searchTerm, sortConfig],
  );

  const handlePageChange = (newPage) => {
    fetchMeasurements(newPage);
  };

  const handleDeleteMeasurement = React.useCallback(
    async (measurement) => {
      try {
        await database.write(async () => {
          await measurement.markAsDeleted();
        });
        if (selectedMeasurement && selectedMeasurement.id === measurement.id) {
          setSelectedMeasurement(null);
        }
        await fetchMeasurements();
        return Promise.resolve();
      } catch (err) {
        console.error("Failed to delete measurement:", err);
        return Promise.reject(err);
      }
    },
    [database, fetchMeasurements, selectedMeasurement],
  );

  React.useEffect(() => {
    fetchMeasurements(1);

    // Remove the interval for automatic refetching, as it might interfere with pagination
    // If you still want periodic updates, you might need a more complex solution
  }, [fetchMeasurements, dateTimeRange]);

  return (
    <div className="flex gap-6">
      <div className="w-2/3">
        <MeasurementTable
          measurements={measurements}
          onSelectMeasurement={setSelectedMeasurement}
          selectedId={selectedMeasurement?.id}
          onDeleteMeasurement={handleDeleteMeasurement}
          currentPage={currentPage}
          totalCount={totalCount}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          isLoading={isLoading}
          dateTimeRange={dateTimeRange}
          setDateTimeRange={setDateTimeRange}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setSortConfig={setSortConfig}
          sortConfig={sortConfig}
          fetchMeasurements={fetchMeasurements}
        />
      </div>
      <div className="w-full lg:w-1/3">
        <MeasurementDetail
          measurement={selectedMeasurement}
          onDelete={handleDeleteMeasurement}
          onClose={() => setSelectedMeasurement(null)}
        />
      </div>
    </div>
  );
};

export default Records;
