import React from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Link } from "@radix-ui/themes";
import { AlertCircle } from "lucide-react";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import BackgroundImage from "../../assets/blob-scene-haikei.svg";
import { Link as ReactRouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createClient } from "@supabase/supabase-js";

import { ProjectKey, ProjectURL } from "../../configs/supabase";
import { Loader2 } from "lucide-react";

const supabase = createClient(ProjectURL, ProjectKey);

export const ForgotPassword = () => {
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [error, setError] = React.useState(null);
  const { t } = useTranslation();

  const handlePasswordReset = async (event) => {
    event.preventDefault();

    setLoading(true);
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });

      if (error) throw error;

      setMessage(t("auth.reset_email_sent"));
      setError(null);
    } catch (error) {
      console.error("Error sending password reset email:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handlePasswordReset}>
      <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px] h-screen">
        <div className="flex items-center justify-center py-12">
          <div className="mx-auto grid w-[350px] gap-6">
            <div className="grid gap-2 text-center">
              <h1 className="text-3xl font-bold">
                {t("auth.forgot_password")}
              </h1>
              <p className="text-balance text-muted-foreground">
                {t("auth.forgot_password_prompt")}
              </p>
              {error && (
                <Alert variant="destructive" className="text-left">
                  <AlertCircle className="h-4 w-4" />
                  <AlertTitle>{t("common.error")}</AlertTitle>
                  <AlertDescription>{t(error)}</AlertDescription>
                </Alert>
              )}
              {message && (
                <Alert variant="success" className="text-left">
                  <AlertCircle className="h-4 w-4" />
                  <AlertTitle>{t("common.success")}</AlertTitle>
                  <AlertDescription>{message}</AlertDescription>
                </Alert>
              )}
            </div>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="email">{t("auth.email")}</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="m@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <Button
                id="reset-button"
                type="submit"
                className="w-full"
                disabled={loading}>
                {loading ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  t("auth.reset_password.button")
                )}
              </Button>
            </div>
            <div className="mt-4 text-center text-sm">
              <Link asChild className="underline">
                <ReactRouterLink to={"/login"}>
                  {t("auth.back_to_login")}
                </ReactRouterLink>
              </Link>
            </div>
          </div>
        </div>
        <div className="hidden bg-muted lg:block">
          <img
            src={BackgroundImage}
            alt="Background"
            className="object-cover object-center h-full w-full"
          />
        </div>
      </div>
    </form>
  );
};

export default ForgotPassword;
