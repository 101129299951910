import React from "react";
import { createClient } from "@supabase/supabase-js";
import { ProjectURL, ProjectKey } from "@/configs/supabase";

const supabase = createClient(ProjectURL, ProjectKey);

export const SessionContext = React.createContext();

export const SessionProvider = ({ children }) => {
  const [session, setSession] = React.useState(null);
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    try {
      supabase.auth.getSession().then(({ data: { session } }) => {
        setSession(session);
        if (session) {
          supabase.auth.getUser().then(({ data: { user } }) => {
            setUser(user);
          });
        } else {
          setUser(null);
        }
      });
    } catch (error) {
      console.error("SessionProvider", error);
    }

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      console.log("onAuthStateChange", _event, session);
      setSession(session);

      if (session) {
        try {
          supabase.auth.getUser().then(({ data: { user } }) => {
            setUser(user);
          });
        } catch (error) {
          console.error("SessionProvider", error);
        }
      } else {
        setUser(null);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <SessionContext.Provider value={{ session, user }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  const context = React.useContext(SessionContext);
  if (!context) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
};
