import { Model } from "@nozbe/watermelondb";
import {
  field,
  relation,
  readonly,
  date,
} from "@nozbe/watermelondb/decorators";

export default class PrinterVendor extends Model {
  static table = "printer_vendors";

  @field("name") name;

  @relation("printer_models", "vendor_id") printerModels;

  @readonly @date("created_at") createdAt;
  @readonly @date("updated_at") updatedAt;
}
