import React, { useState, useEffect, useCallback } from "react";
import { useDatabase } from "@/contexts/DatabaseContext/DatabaseContext";
import { useTranslation } from "react-i18next";
import { useToast } from "@/components/ui/use-toast";
import { Q } from "@nozbe/watermelondb";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";

import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  MoreVertical,
  Plus,
  Upload,
  Download,
  SquareDashedKanban,
} from "lucide-react";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";

const EmptyState = ({ onAddNew }) => {
  const { t } = useTranslation();
  return (
    <div className="text-center py-12">
      <SquareDashedKanban className="mx-auto h-12 w-12 text-muted-foreground" />
      <h3 className="mt-4 text-lg font-semibold">{t("inventory.no_items")}</h3>
      <p className="mt-2 text-muted-foreground">
        {t("inventory.no_items_description")}
      </p>
      <Button onClick={onAddNew} className="mt-4">
        {t("common.add")}
      </Button>
    </div>
  );
};

const Inventory = () => {
  const { database } = useDatabase();
  const { t } = useTranslation();
  const { toast } = useToast();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "key_value",
    direction: "asc",
  });
  const [columns, setColumns] = useState(["編號"]);
  const [keyFieldName, setKeyFieldName] = useState("編號");
  const [isAddItemDialogOpen, setIsAddItemDialogOpen] = useState(false);
  const [newItem, setNewItem] = useState({});
  const [newField, setNewField] = useState({ name: "", value: "" });
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedRows, setSelectedRows] = React.useState(new Set());

  const fetchItems = useCallback(
    async (page = 1) => {
      setIsLoading(true);
      try {
        const itemsCollection = database.get("inventory_items");
        let query = itemsCollection.query(
          Q.sortBy(
            sortConfig.key === keyFieldName ? "key_value" : sortConfig.key,
            sortConfig.direction === "asc" ? Q.asc : Q.desc
          )
        );

        if (searchTerm) {
          query = query.extend(
            Q.or(
              Q.where(
                "key_value",
                Q.like(`%${Q.sanitizeLikeString(searchTerm)}%`)
              )
            )
          );
        }

        const count = await query.fetchCount();
        setTotalCount(count);

        const offset = (page - 1) * itemsPerPage;
        const fetchedItems = await query
          .extend(Q.skip(offset), Q.take(itemsPerPage))
          .fetch();

        const itemsWithFields = await Promise.all(
          fetchedItems.map(async (item) => {
            const fields = await item.fields.fetch();
            const fieldObject = fields.reduce((acc, field) => {
              acc[field.fieldName] = field.fieldValue;
              return acc;
            }, {});
            return {
              id: item.id,
              [keyFieldName]: item.keyValue,
              ...fieldObject,
            };
          })
        );

        setItems(itemsWithFields);
        setCurrentPage(page);
      } catch (err) {
        console.error("Failed to fetch inventory items:", err);
        toast({
          title: t("common.error"),
          description: t("inventory.error.failed_fetching_items"),
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      database,
      t,
      toast,
      itemsPerPage,
      searchTerm,
      sortConfig,
      columns,
      keyFieldName,
    ]
  );

  useEffect(() => {
    const fetchColumns = async () => {
      const itemsCollection = database.get("inventory_items");
      const lastItem = await itemsCollection
        .query(Q.sortBy("created_at", Q.desc), Q.take(1))
        .fetch();

      if (lastItem.length > 0) {
        const fields = await lastItem[0].fields.fetch();
        // reverse the field order
        fields.reverse();

        const fetchedColumns = [
          keyFieldName,
          ...fields
            .filter((field) => field.fieldName !== keyFieldName)
            .map((field) => field.fieldName),
        ];
        if (JSON.stringify(columns) !== JSON.stringify(fetchedColumns)) {
          setColumns(fetchedColumns);
        }
      } else {
        if (JSON.stringify(columns) !== JSON.stringify([keyFieldName])) {
          setColumns([keyFieldName]);
        }
      }
    };
    fetchColumns();
  }, [database, keyFieldName]);

  useEffect(() => {
    fetchItems(1);
  }, [fetchItems]);

  const handlePageChange = (newPage) => {
    fetchItems(newPage);
  };

  const handleSort = (key) => {
    return;
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const handleImport = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(data);

        const worksheet = workbook.getWorksheet(1);
        const rows = worksheet.getSheetValues();

        const headers = rows[1];
        const newKeyFieldName = headers[1];

        const itemsToImport = rows.slice(2).map((row) => {
          const item = {};
          headers.forEach((header, index) => {
            if (row[index] !== undefined) {
              // if is object containing "result", then use result
              if (
                typeof row[index] === "object" &&
                row[index].result !== undefined
              ) {
                item[header] = row[index].result.toString();
              } else if (
                typeof row[index] === "object" &&
                row[index].text !== undefined
              ) {
                item[header] = row[index].text.toString();
              } else {
                item[header] = row[index].toString();
              }
            }
          });
          return item;
        });

        try {
          await database.write(async () => {
            for (const item of itemsToImport) {
              const keyValue = item[newKeyFieldName];
              const newItem = await database
                .get("inventory_items")
                .create((record) => {
                  record.keyValue = keyValue;
                });

              const batchFields = [];

              for (const [header, value] of Object.entries(item)) {
                if (header !== newKeyFieldName) {
                  batchFields.push(
                    database
                      .get("inventory_item_fields")
                      .prepareCreate((field) => {
                        field.item.set(newItem);
                        field.fieldName = header;
                        field.fieldValue = value;
                      })
                  );
                }
              }

              await database.batch(...batchFields);
            }
          });

          toast({
            title: t("common.success"),
            description: t("inventory.success.items_imported"),
          });
          setColumns(headers);
          fetchItems(1);
        } catch (err) {
          console.error("Failed to import items:", err);
          toast({
            title: t("common.error"),
            description: t("inventory.error.failed_importing_items"),
            variant: "destructive",
          });
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleExport = async () => {
    try {
      const allItems = await database.get("inventory_items").query().fetch();
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Inventory");

      worksheet.addRow(columns);

      for (const item of allItems) {
        const fields = await item.fields.fetch();
        const row = [];
        columns.forEach((column) => {
          const field = fields.find((f) => f.fieldName === column);
          row.push(field ? field.fieldValue : "");
        });
        worksheet.addRow(row);
      }

      const buffer = await workbook.xlsx.writeBuffer();
      FileSaver.saveAs(new Blob([buffer]), "inventory.xlsx");

      toast({
        title: t("common.success"),
        description: t("inventory.success.items_exported"),
      });
    } catch (err) {
      console.error("Failed to export items:", err);
      toast({
        title: t("common.error"),
        description: t("inventory.error.failed_exporting_items"),
        variant: "destructive",
      });
    }
  };

  const handleAddItem = async () => {
    if (!newItem[keyFieldName]) {
      toast({
        title: t("common.error"),
        description: t("inventory.error.key_field_required"),
        variant: "destructive",
      });
      return;
    }

    try {
      await database.write(async () => {
        const newItemRecord = await database
          .get("inventory_items")
          .create((record) => {
            record.keyValue = newItem[keyFieldName];
          });

        const batchFields = [];

        for (const [fieldName, fieldValue] of Object.entries(newItem)) {
          if (fieldName !== keyFieldName) {
            batchFields.push(
              database.get("inventory_item_fields").prepareCreate((field) => {
                field.item.set(newItemRecord);
                field.fieldName = fieldName;
                field.fieldValue = fieldValue;
              })
            );
          }
        }

        await database.batch(...batchFields);
      });

      toast({
        title: t("common.success"),
        description: t("inventory.success.item_added"),
      });

      // Update columns if new fields were added
      const newColumns = [...new Set([keyFieldName, ...Object.keys(newItem)])];
      if (newColumns.length !== columns.length) {
        setColumns(newColumns);
      }
    } catch (err) {
      console.error("Failed to add item:", err);
      toast({
        title: t("common.error"),
        description: t("inventory.error.failed_adding_item"),
        variant: "destructive",
      });
    } finally {
      setIsAddItemDialogOpen(false);
      setNewItem({});
      fetchItems(currentPage);
    }
  };

  const handleAddField = () => {
    if (newField.name && !columns.includes(newField.name)) {
      setColumns([...columns, newField.name]);
      setNewItem({ ...newItem, [newField.name]: newField.value });
      setNewField({ name: "", value: "" });
    }
  };

  const handleDelete = async (item) => {
    if (window.confirm(t("inventory.confirm_delete"))) {
      try {
        await database.write(async () => {
          const itemToDelete = await database
            .get("inventory_items")
            .find(item.id);
          await itemToDelete.markAsDeleted();
        });
        await fetchItems(currentPage);
        toast({
          title: t("common.success"),
          description: t("inventory.success.item_deleted"),
        });
      } catch (err) {
        console.error("Failed to delete item:", err);
        toast({
          title: t("common.error"),
          description: t("inventory.error.failed_deleting_item"),
          variant: "destructive",
        });
      }
    }
  };

  const handleImportBtnClick = () => {
    document.getElementById("import-file").click();
  };

  const handleRowSelection = (itemId) => {
    setSelectedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(itemId)) {
        newSet.delete(itemId);
      } else {
        newSet.add(itemId);
      }
      return newSet;
    });
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedRows(new Set(items.map((item) => item.id)));
    } else {
      setSelectedRows(new Set());
    }
  };

  const handleBulkDelete = async () => {
    if (window.confirm(t("inventory.confirm_bulk_delete"))) {
      try {
        const itemsToBeDeleted = items.filter((item) =>
          selectedRows.has(item.id)
        );

        await database.write(async () => {
          for (const item of itemsToBeDeleted) {
            const itemToDelete = await database
              .get("inventory_items")
              .find(item.id);
            await itemToDelete.markAsDeleted();
          }
        });

        toast({
          title: t("common.success"),
          description: t("inventory.success.items_deleted"),
        });
        setSelectedRows(new Set());
        await fetchItems(currentPage);
      } catch (error) {
        console.error("Failed to delete items:", error);
        toast({
          title: t("common.error"),
          description: t("inventory.error.failed_deleting_items"),
          variant: "destructive",
        });
      }
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("inventory.title")}</CardTitle>
        <CardDescription>{t("inventory.description")}</CardDescription>
        <div className="flex flex-col sm:flex-row gap-4">
          <Input
            placeholder={t("common.search")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button onClick={() => setIsAddItemDialogOpen(true)}>
            <Plus className="mr-2 h-4 w-4" />
            {t("common.add")}
          </Button>
          <label htmlFor="import-file">
            <Button as="span" onClick={() => handleImportBtnClick()}>
              <Upload className="mr-2 h-4 w-4" />
              {t("common.import")}
            </Button>
          </label>
          <input
            id="import-file"
            type="file"
            accept=".xlsx,.xls,.csv"
            style={{ display: "none" }}
            onChange={handleImport}
          />
          <Button onClick={handleExport}>
            <Download className="mr-2 h-4 w-4" />
            {t("common.export")}
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        {selectedRows.size > 0 && (
          <div className="mb-4 p-2 bg-muted rounded flex justify-between items-center">
            <span>{t("inventory.selected", { count: selectedRows.size })}</span>
            <Button onClick={handleBulkDelete} variant="destructive">
              {t("common.delete")}
            </Button>
          </div>
        )}
        {items.length === 0 && !isLoading ? (
          <EmptyState onAddNew={() => setIsAddItemDialogOpen(true)} />
        ) : (
          <div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[50px]">
                    <Checkbox
                      checked={
                        selectedRows.size === items.length
                          ? true
                          : selectedRows.size !== 0
                            ? "indeterminate"
                            : false
                      }
                      onCheckedChange={handleSelectAll}
                    />
                  </TableHead>
                  {columns.map((column) => (
                    <TableHead key={column} onClick={() => handleSort(column)}>
                      {column}
                    </TableHead>
                  ))}
                  <TableHead>{t("common.actions")}</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {items.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedRows.has(item.id)}
                        onCheckedChange={() => handleRowSelection(item.id)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </TableCell>
                    {columns.map((column) => (
                      <TableCell key={column}>{item[column] || ""}</TableCell>
                    ))}
                    <TableCell>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" className="h-8 w-8 p-0">
                            <MoreVertical className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuItem onClick={() => handleDelete(item)}>
                            {t("common.delete")}
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="mt-4">
              <Pagination>
                <PaginationContent>
                  <PaginationItem>
                    <PaginationPrevious
                      onClick={() =>
                        handlePageChange(Math.max(currentPage - 1, 1))
                      }
                      disabled={currentPage === 1}
                    />
                  </PaginationItem>
                  {[...Array(Math.ceil(totalCount / itemsPerPage))].map(
                    (_, index) => (
                      <PaginationItem key={index}>
                        <PaginationLink
                          onClick={() => handlePageChange(index + 1)}
                          isActive={currentPage === index + 1}
                        >
                          {index + 1}
                        </PaginationLink>
                      </PaginationItem>
                    )
                  )}
                  <PaginationItem>
                    <PaginationNext
                      onClick={() =>
                        handlePageChange(
                          Math.min(
                            currentPage + 1,
                            Math.ceil(totalCount / itemsPerPage)
                          )
                        )
                      }
                      disabled={
                        currentPage === Math.ceil(totalCount / itemsPerPage)
                      }
                    />
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            </div>
          </div>
        )}
      </CardContent>
      <Dialog open={isAddItemDialogOpen} onOpenChange={setIsAddItemDialogOpen}>
        <DialogContent className={`max-h-[80vh] overflow-y-auto`}>
          <DialogHeader>
            <DialogTitle>{t("inventory.add_item")}</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div>
              <Label for="key_value">{keyFieldName}</Label>
              <Input
                key={keyFieldName}
                placeholder={keyFieldName}
                value={newItem[keyFieldName] || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, [keyFieldName]: e.target.value })
                }
              />
            </div>

            {columns
              .filter((col) => col !== keyFieldName)
              .map((column) => (
                <div key={column}>
                  <Label for={column}>{column}</Label>
                  <Input
                    placeholder={column}
                    value={newItem[column] || ""}
                    onChange={(e) =>
                      setNewItem({ ...newItem, [column]: e.target.value })
                    }
                  />
                </div>
              ))}
            <div className="flex  gap-2">
              <Input
                placeholder={t("inventory.new_field_name")}
                value={newField.name}
                onChange={(e) =>
                  setNewField({ ...newField, name: e.target.value })
                }
              />
              <Input
                placeholder={t("inventory.new_field_value")}
                value={newField.value}
                onChange={(e) =>
                  setNewField({ ...newField, value: e.target.value })
                }
              />
              <Button onClick={handleAddField}>{t("common.add_field")}</Button>
            </div>
          </div>
          <DialogFooter>
            <Button onClick={handleAddItem}>{t("common.save")}</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default Inventory;
