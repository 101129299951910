import CommunicationAdapter from "./Communication";

export class BluetoothAdapter extends CommunicationAdapter {
  constructor(device, serviceUuid, characteristicUuid, params) {
    super();
    this._device = device;
    this._characteristic = null;
    this._params = params || {};

    this._advertisementData = {}
  }

  get device() {
    return this._device;
  }

  get connection_status() {
    return this._device.gatt.connected ? "connected" : "disconnected";
  }

  get advertisementData() {
    return this._advertisementData;
  }

  async connect(serviceUuid, characteristicUuid) {
    const server = await this._device.gatt.connect();
    const service = await server.getPrimaryService(serviceUuid);
    this._characteristic = await service.getCharacteristic(characteristicUuid);

    this._device.addEventListener("gattserverdisconnected", () => {
      this._characteristic = null;
    });

    this._device.addEventListener("advertisementreceived", ((event) => {
      console.log("Advertisement received", event);

      this._advertisementData.rssi = event.rssi;
      this._advertisementData.txPower = event.txPower;
      this._advertisementData.uuids = event.uuids;

    }).bind(this));

    await this._device.watchAdvertisements();
  }

  async disconnect() {
    if (this._device && this._device.gatt.connected) {
      await this.device.gatt.disconnect();
    }
  }

  async receiveData() {
    const value = await this._characteristic.readValue();
    return value;
  }

  async notify(callback) {
    await this._characteristic.startNotifications();
    this._characteristic.addEventListener(
      "characteristicvaluechanged",
      callback
    );
  }

  async sendData(data) {
    let buffer = data;

    if (typeof data === "string") {
      buffer = new TextEncoder().encode(data);
    } else if (Array.isArray(data)) {
      buffer = new Uint8Array(data);
    }

    await this._characteristic.writeValue(buffer);
  }
}

export default BluetoothAdapter;
