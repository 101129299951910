import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useTranslation } from "react-i18next";
import { PlusCircle } from "lucide-react";
export const PrintersCard = () => {
  const { t } = useTranslation();
  const columns = [
    {
      key: t("devices.table.name"),
      name: t("devices.table.name"),
      className: "text-left", // Add className field here
    },
    {
      key: t("devices.table.connection_type"),
      name: t("devices.table.connection_type"),
      className: "text-left", // Add className field here
    },
    {
      key: t("devices.table.vendor"),
      name: t("devices.table.vendor"),
      className: "text-left", // Add className field here
    },
    {
      key: t("devices.table.model"),
      name: t("devices.table.model"),
      className: "text-left", // Add className field here
    },
    {
      key: t("devices.table.status"),
      name: t("devices.table.status"),
      className: "text-left", // Add className field here
    },
    {
      key: t("devices.table.action"),
      name: t("devices.table.action"),
      className: "text-left", // Add className field here
    },
  ];
  return (
    <Card>
      <CardHeader>
        <div className={"flex flex-row justify-between"}>
          <div>
            <CardTitle>{t("devices.printer")}</CardTitle>
            <CardDescription>
              {t("devices.printer.description")}
            </CardDescription>
          </div>
          <div className={"flex flex-row justify-end"}>
            <Button className={"gap-2"} onClick={() => {}}>
              <PlusCircle />
              <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                {t("devices.printer.add")}
              </span>
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              {columns.map((column) => (
                <TableHead key={column.key} className={column.className}>
                  {column.name}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell className="font-medium">INV001</TableCell>
              <TableCell>Paid</TableCell>
              <TableCell>Credit Card</TableCell>
              <TableCell className="text-right">$250.00</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter>
        <p>Card Footer</p>
      </CardFooter>
    </Card>
  );
};
