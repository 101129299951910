import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useTranslation } from "react-i18next";
import { useBLE } from "../../contexts/BLEContext/BLEContext";
import { PrintersCard } from "./PrintersCard";
import { ScalesCard } from "./ScalesCard";



export const Devices = () => {
  const ble = useBLE();
  const { t } = useTranslation();

  return (
    <div>
      <Tabs defaultValue="scale" className="w-full">
        <TabsList>
          <TabsTrigger value="scale">{t("devices.scale")}</TabsTrigger>
          <TabsTrigger value="printer">{t("devices.printer")}</TabsTrigger>
        </TabsList>
        <TabsContent value="scale">
          <ScalesCard />
        </TabsContent>
        <TabsContent value="printer">
          <PrintersCard />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Devices;
