import { Model } from "@nozbe/watermelondb";
import {
  field,
  children,
  readonly,
  date,
} from "@nozbe/watermelondb/decorators";

export default class InventoryItem extends Model {
  static table = "inventory_items";
  static associations = {
    inventory_item_fields: { type: "has_many", foreignKey: "item_id" },
  };

  @field("key_value") keyValue;

  @children("inventory_item_fields") fields;

  @readonly @date("created_at") createdAt;
  @readonly @date("updated_at") updatedAt;
}
