import React from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Link } from "@radix-ui/themes";
import { AlertCircle } from "lucide-react";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import BackgroundImage from "../../assets/blob-scene-haikei.svg";
import {
  Link as ReactRouterLink,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createClient } from "@supabase/supabase-js";

import { ProjectKey, ProjectURL } from "../../configs/supabase";
import { useSession } from "@/contexts/SessionContext";
import { Loader2 } from "lucide-react";

const supabase = createClient(ProjectURL, ProjectKey);

export const Login = () => {
  const location = useLocation();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const { t } = useTranslation();
  const { session } = useSession();

  const handleLogin = async (event) => {
    event.preventDefault();

    setLoading(true);
    try {
      const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;

      setError(null);
    } catch (error) {
      console.error("Error logging in:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (session) {
    return <Navigate to={location.state?.referer ?? "/home"} />;
  }

  return (
    <form onSubmit={handleLogin} data-testid="login-form">
      <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px] h-screen">
        <div className="flex items-center justify-center py-12">
          <div className="mx-auto grid w-[350px] gap-6">
            <div className="grid gap-2 text-center">
              <h1 className="text-3xl font-bold">{t("auth.signin")}</h1>
              <p className="text-balance text-muted-foreground">
                {t("auth.signin.prompt")}
              </p>
              {error && (
                <Alert variant="destructive" className="text-left">
                  <AlertCircle className="h-4 w-4" />
                  <AlertTitle data-testid="alert-title">{t("common.error")}</AlertTitle>
                  <AlertDescription data-testid="alert-description">{t(error)}</AlertDescription>
                </Alert>
              )}
            </div>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="email">{t("auth.email")}</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="m@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  data-testid="email-input"
                />
              </div>
              <div className="grid gap-2">
                <div className="flex justify-between items-center">
                  <Label htmlFor="password">{t("auth.password")}</Label>
                  <Link
                    asChild
                    className="ml-auto inline-block text-sm underline"
                  >
                    <ReactRouterLink to="/forgot-password">
                      {t("auth.forget_password")}
                    </ReactRouterLink>
                  </Link>
                </div>
                <Input
                  id="password"
                  type="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  data-testid="password-input"
                />
              </div>
              <Button
                id="login-button"
                type="submit"
                className="w-full"
                disabled={loading}
                data-testid="login-button"
              >
                {loading ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  t("auth.signin.button")
                )}
              </Button>
              <Button
                id="google-button"
                variant="outline"
                className="w-full"
                disabled
                data-testid="google-button"
              >
                {t("auth.signin_with_google")}
              </Button>
            </div>
            <div className="mt-4 text-center text-sm">
              {t("auth.dont_have_account")}{" "}
              <Link asChild className="underline">
                <ReactRouterLink to={"/signup"}>
                  {t("auth.signup.link")}
                </ReactRouterLink>
              </Link>
            </div>
          </div>
        </div>
        <div className="hidden bg-muted lg:block">
          <img
            src={BackgroundImage}
            alt="Background"
            className="object-cover object-center h-full w-full"
          />
        </div>
      </div>
    </form>
  );
};

export default Login;
